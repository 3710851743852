import React, { useState, useEffect, useRef } from "react";
import { Collapse, Button } from "antd";
import axios from "axios";
import { baseURL } from "../../constants";
import Arrow from "../../assets/images/btn-arrow.png";
import "../../assets/scss/_faqs.scss";

const { Panel } = Collapse;
const Faqs = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dataFetched = useRef(false);

  useEffect(() => {
    setError(false);
    setLoading(true);
    axios
      .get(`${baseURL}/faqs`)
      .then(res => {
        console.log('FAQs', res.data);
        setFaqData(res.data?.data);
      })
      .catch(err => {
        setError(true);
        console.log(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [dataFetched]);

  return (
    <div className="faqs">
      <div className="buy-token-btn">
        <Button className="wallet-btn">
          Connect Wallet <img src={Arrow} alt="arrow" />
        </Button>
        {/* <Button className="wallet-btn">
          Add Your Wallet Address Before Buy <img src={Arrow} alt="arrow" />
        </Button> */}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="profile-cont">
              <h4>FAQ</h4>
              {loading ?
                <div className="text-center py-5 my-5">
                  <div className="spinner-border" />
                  <p className="mt-2">Loading data...</p>
                </div>
                :
                error || faqData?.length === 0 ?
                  <div className="text-center py-5 my-5">
                    <h4>No FAQs found!</h4>
                  </div>
                  :
                  <Collapse defaultActiveKey={["0"]} onChange={onChange}>
                    {faqData.map((item, index) => (
                      <Panel
                        header={item.question}
                        key={index}
                      >
                        <p>
                          {item.ans}
                        </p>
                      </Panel>
                    ))}
                  </Collapse>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
