import React, { useEffect, useState, useRef } from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Setting from "../../assets/images/setting.png";
import True from "../../assets/images/ok1.png";
import Not from "../../assets/images/not1.png";
import I from "../../assets/images/ig.png";
import Delete from "../../assets/images/delete.svg";
import Eye from "../../assets/images/eye.svg";
import Wallet from "../../assets/images/wallet.svg";
import Empty from "../../assets/images/empty1.png";
import Hint from "../../assets/images/I1.png";
import Search from "../../assets/images/search.png";
import "../../assets/scss/_transactions.scss";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { baseURL } from "../../constants";
import TransactionModal from "../../components/TransactionModal/TransactionModal";

const Transactions = () => {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedTransactionID, setSelectedTransactionID] = useState();
  const dataFetched = useRef(false);

  const token = localStorage.getItem("token");
  const {t} = useTranslation();

  useEffect(() => {
    if (token) {
      setError(false);
      setLoading(true);
      axios
        .get(`${baseURL}/transactions`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          console.log('Transactions', res.data);
          setTransactionData(res.data?.data);
        })
        .catch(err => {
          setError(true);
          console.log(err.response?.data);
        })
        .finally(() => setLoading(false));
    } else {
      setError("no_token");
    }
  }, [dataFetched]);

  return (
    <div className="transactions">
      <div className="transactions-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in to Search"
                />
                <img className="search-icon" src={Search} alt="search" />
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={Setting} alt="setting" />
                    Setting
                  </button>
                  <div className="dropdown-menu">
                    <h6>Types</h6>
                    <Checkbox onChange={onChange}>Any Types</Checkbox>
                    <Checkbox onChange={onChange}>Purchase</Checkbox>
                    <h6>Status</h6>
                    <Checkbox onChange={onChange}>Show All</Checkbox>
                    <Checkbox onChange={onChange}>Approved</Checkbox>
                    <Checkbox onChange={onChange}>Pending</Checkbox>
                    <Checkbox onChange={onChange}>Canceled</Checkbox>
                    {/* <Link className="dropdown-item" href="#">
                      Action
                    </Link>
                    <Link className="dropdown-item" href="#">
                      Another action
                    </Link>
                    <Link className="dropdown-item" href="#">
                      Something else here
                    </Link> */}
                  </div>
                </div>
                {/* <Button>
                  <img src={Setting} alt="setting" />
                  Setting
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transactions-data">
        <div className="container">
          {loading ?
            <div className="text-center py-5 my-5">
              <div className="spinner-border" />
              <p className="mt-2">Loading data...</p>
            </div>
            :
            error ?
              <div className="text-center py-5 my-5">
                <h4>{error === "no_token" ? t("You must login to view transactions.") : t("Unable to fetch data!")}</h4>
              </div>
              :
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("Transaction No.")}</th>
                      <th>{t("Token")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("EUR Amount")}</th>
                      <th>{t("To")}</th>
                      <th>{t("Type")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData?.length === 0 ?
                      <tr>
                        <td colSpan={6}>
                          <p className="text-center my-4">{t("No transactions found!")}</p>
                        </td>
                      </tr>
                      :
                      transactionData?.map((item, index) => (


                        <tr key={index}>
                          <td>
                            <img src={True} alt="check" />
                            {item.transaction_no}
                            <span>2018-08-24 10:45PM</span>
                          </td>
                          <td>
                            {item.token}
                            <span>PBC</span>
                          </td>
                          <td>
                            {item.amount}
                            <span>
                              ETH <img src={I} alt="hint" />
                            </span>
                          </td>
                          <td>
                            {item.eur_amount}
                            <span>
                              EUR <img src={I} alt="hint" />
                            </span>
                          </td>
                          <td>
                            {item.to}
                            <span>{new Date(item.created_at).toLocaleString()}</span>
                          </td>
                          <td>
                            <Tooltip placement="top" title="Pay Now">
                              <Button className="wallet-btn">
                                <ReactSVG src={Wallet} alt="wallet" />
                              </Button>
                            </Tooltip>
                            <Tooltip placement="top" title="View">
                              <Button
                                className="transcation-btn eye-btn"
                                data-toggle="modal"
                                data-target="#transaction"
                                onClick={()=>setSelectedTransactionID(item.id)}
                              >
                                <ReactSVG src={Eye} alt="eye" />
                              </Button>
                            </Tooltip>
                            <Tooltip placement="top" title="Delete">
                              <Button className="delete-btn">
                                <ReactSVG src={Delete} alt="delete" />
                              </Button>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
          }
          {/* Transaction Modal */}
          <TransactionModal transactionID={selectedTransactionID} />
          {/* Transaction Modal */}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
