import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import ModalImage from "../../assets/images/modal-im-alt.png";
import Close from "../../assets/images/close-grey.png";
import '../../assets/scss/_metamask-modal.scss';


const MetamaskModal = (props) => {
  const { show, setShow } = props;
  const { t } = useTranslation();

  return (
    <Modal
      className="metamask-modal"
      id="metamaskModal"
      open={show}
      footer={null}
      closable={false}
    >
      <div className="close-btn" onClick={()=>setShow(false)}>
        <img src={Close} alt="Close" />
      </div>
      <div className="metamask-modal-content">
        <img
          className="modal-image"
          src={ModalImage}
          alt="logo"
        />
        <h4>{t("Install Metamask")}</h4>
        <div className="buttons">
        <Button className="install-now" href="https://metamask.io/download/" target="_blank">{t("Install Now")}</Button>
        <Button className="install-later" onClick={()=>setShow(false)}>{t("Install Later")}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default MetamaskModal;
