import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import ModalImage from "../../assets/images/modal-im-alt.png";
import Close from "../../assets/images/close-grey.png";
import Success from "../../assets/images/transaction-success.png";
import Failed from "../../assets/images/transaction-error.png";
import '../../assets/scss/_metamask-modal.scss';


const TransactionResponseModal = (props) => {
  const { show, setShow, success } = props;

  return (
    <Modal
      className={"metamask-modal " + (success ? "transaction-success" : "transaction-failed")}
      id="transaction-response-modal"
      open={show}
      footer={null}
      closable={false}
    >
      <div className="close-btn" onClick={() => setShow(false)}>
        <img src={Close} alt="Close" />
      </div>
      <div className="metamask-modal-content">
        <img
          className="modal-image"
          src={ModalImage}
          alt="logo"
        />
        <div className="text-center">
          <img
            className="transaction-status-img"
            alt="Transaction Status"
            src={success ? Success : Failed}
          />
        </div>
        <h4>{success ? "Transaction Successful" : "Transaction Failed"}</h4>
      </div>
    </Modal>
  );
};

export default TransactionResponseModal;
