import React, { useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// Routes
import Homepage from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import BuyToken from "./pages/BuyToken/BuyToken";
import Transactions from "./pages/Transactions/Transactions";
import Withdraw from "./pages/withdraw/Withdraw";
import Profile from "./pages/Profile/Profile";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Faqs from "./pages/Faqs/Faqs";
import MyToken from "./pages/MyToken/MyToken";
import FaLog from "./pages/FaLog/FaLog";

function App() {
  const [language, setLanguage] = useState("gm");
  const expiryTime = localStorage.getItem('expiryTime')

  useEffect(() => {
    if (expiryTime) {
      let now = new Date();
      let expiry = new Date(expiryTime);
      console.log('Now', now.toString());
      console.log('Expiry', expiry.toString());
      if (now > expiry) {
        localStorage.clear();
        window.location.reload();
      }
    }
  })

  return (
    <BrowserRouter>
      <Header pageName="All" />
      <Routes>
        <Route
          path="/"
          element={<Homepage language={language} setLanguage={setLanguage} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/privacy-policy" element={<Privacy language={language} />} />
        <Route path="/terms-conditions" element={<Terms language={language} />} />
        <Route path="/buy-token" element={<BuyToken />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/my-token" element={<MyToken />} />
        <Route path="/privacy" element={<Privacy language={language} />} />
        <Route path="/terms" element={<Terms language={language} />} />
        <Route path="/authentication" element={<FaLog />} />
      </Routes>
      <Footer language={language} setLanguage={setLanguage} />
    </BrowserRouter>
  );
}

export default App;
