import React, { useState } from "react";
import { Button, Radio } from "antd";
import axios from "axios";
import { baseURL, formatAmount } from "../../constants";
import { useTranslation } from "react-i18next";

import Logo from "../../assets/images/curr.png";
import USD from "../../assets/images/dollar.svg";
import EURO from "../../assets/images/euro.svg";
import USDC from "../../assets/images/usdc.svg";
import BUSD from "../../assets/images/busd.svg";
import I from "../../assets/images/i.png";
import IB from "../../assets/images/ib.png";
import Wallet from "../../assets/images/white-w.png";
import Stripe from "../../assets/images/stripe.png";
import Paypal from "../../assets/images/paypal.png";
import Bank from "../../assets/images/bank.png";
import "../../assets/scss/_token.scss";
import { ReactSVG } from "react-svg";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";

const plainOptions = [
  { name: "USD", price: "0.074947  USD", value: "usd", image: USD },
  { name: "EUR", price: "0.07  EUR", value: "eur", image: EURO },
  { name: "USDC", price: "0.074947  USDC", value: "usdc", image: USDC },
  { name: "BUSD", price: "0.07  BUSD", value: "busd", image: BUSD },
];

const conversionRates = {
  "USD": 0.074947,
  "EUR": 0.07,
}

const BuyToken = () => {
  const [currencyVal, setCurrencyVal] = useState("");
  const [pbcAmount, setPbcAmount] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saveTransactionRes, setSaveTransactionRes] = useState({});
  const {t} = useTranslation();

  const token = localStorage.getItem("token");

  const saveTransaction = () => {
    let body = {
      transaction_no: "TNX1002",
      token: pbcAmount,
      amount: paymentAmount,
      eur_amount: paymentAmount,
      to: "Dummy Wallet Address",
      type: "Purchased"
    };
    setSaveTransactionRes({})
    setLoading(true);
    axios
      .post(`${baseURL}/create-transaction`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log('Save Transaction Response', res.data);
        setSaveTransactionRes(res.data);
      })
      .catch(err => {
        setSaveTransactionRes({message: 'Error: Unable to save transaction'});
        console.log(err.response?.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="buy-token">
      <ConnectWallet />
      <div className="token-price">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="token-price-cont">
                <h3>{t("Choose currency")}</h3>
                <p>
                {t("You can buy our")}
                </p>
                <div className="currency-select">
                  {plainOptions.map((item, index) => (
                    <Radio.Button
                      onClick={() => setCurrencyVal(item.value)}
                      key={index}
                      className={`${currencyVal === item.value ? item.value : ""
                        }`}
                    >
                      <ReactSVG src={item.image} alt="" />
                      <div>
                        <h6> {item.name}</h6>
                        <p className="mb-0">{item.price}</p>
                      </div>
                    </Radio.Button>
                  ))}
                </div>
                <h4>{t("Amount of Contribution")}</h4>
                <p>
                  {t("Enter the amount you would like")}
                </p>
                <div className="custom-input">
                  <div className="coin-input">
                    <input type="number"
                      className="form-control"
                      value={pbcAmount}
                      disabled={!currencyVal}
                      onChange={e => {
                        setPbcAmount(e.target.value);
                        setPaymentAmount(e.target.value * conversionRates[currencyVal === "eur" ? "EUR" : "USD"]);
                      }} />
                    <p>PBC</p>
                  </div>

                  <p>=</p>
                  <h5>
                    {formatAmount(paymentAmount)}
                    <span>{currencyVal}</span>
                  </h5>
                </div>
                <p className="inst">
                  <img src={I} alt="hint" />
                  {t("52.34 BUSD (700 PBC) Minimum contribution amount is required.")}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="token">
                <img
                  src={Logo}
                  alt="logo"
                  className="img-fluid mx-auto d-block"
                />
                <h4>
                  <small>{t("Tokens Balance")} </small>{" "}
                  <span className="br-block"></span> 120,000,000{" "}
                  <small>PBC</small>
                </h4>
                <div className="cotribution">
                  <p>{t("Your Contribution")}</p>
                  <div className="contribution-cont">
                    <div>
                      <h6>2.645</h6>
                      <p>ETH</p>
                    </div>
                    <div>
                      <h6>2.645</h6>
                      <p>BTC</p>
                    </div>
                    <div>
                      <h6>2.645</h6>
                      <p>LTC</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="make-payment">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="make-payment-cont">
                <div className="calculate">
                  <div>
                    <p>+ {t("Sale Bonus")} 0%</p>
                    <h6>0</h6>
                  </div>
                  <div>
                    <p>{t("TOTAL")} PBC</p>
                    <h6>{formatAmount(pbcAmount, 3)}</h6>
                  </div>
                </div>
                <p className="err">
                  {t("Your contribution will be calculated")}
                </p>
                {saveTransactionRes && <p className="api-response-message" style={{color: (saveTransactionRes.status === 200 ? "green" : "red")}}><strong>{saveTransactionRes.message}</strong></p>}
                <Button
                  className="payment-btn"
                  data-toggle="modal"
                  data-target="#payment"
                  disabled={loading || pbcAmount < 700}
                >
                  {
                    loading ?
                      <div className="spinner-border mx-5" />
                      :
                      <>
                        <img src={Wallet} alt="wallet" />
                        {t("Make Payment")}
                      </>
                  }



                </Button>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="payment"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <div className="modal-body">
                        <div className="payment-modal-cont">
                          <h4>{t("Make Payment")}</h4>
                          <p>Select Payment Method</p>
                          <div className="payment-btns">
                            <Button>
                              <img src={Stripe} alt="stripe" />
                            </Button>
                            <Button>
                              <img src={Paypal} alt="paypal" />
                            </Button>
                            <Button>
                              <img src={Bank} alt="bank" />
                            </Button>
                          </div>
                          <Button
                            className="submit-btn"
                            onClick={saveTransaction}
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <p className="inst">
                  <img src={IB} alt="hint" />
                  {t("Token will appear in your account")}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ether">
                <h5>{t("Token Sales")}</h5>
                <p>{t("PBC Token Price")}</p>
                <h4>
                  1 PBC = <span>0.07EUR</span>
                </h4>
                <p>{t("Exchange Rate")}</p>
                <h4>1 EUR = 1.071 USD = 0.8923 GBP</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyToken;
