import React, { useEffect } from "react";
import "../../assets/scss/_privacy.scss";

const Terms = (props) => {
  const { language } = props;

  return (
    <div className="privacy">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="privacy-cont">
              <h3>Terms of Service</h3>
              <p>PAYBIUS </p>
              {language === "gm" ?
                <>
                  <p className="first-para">
                    Allgemeine Geschäftsbedingungen - Paybius
                  </p>
                  <h4>A. Geltungsbereich</h4>
                  <h5>(1) Auf der</h5>
                  <p>
                    Webseite {/* eslint-disable-next-line */}
                    {" "}
                    bietet die Paybius GmbH
                    ("Paybius GmbH") denPaybius Coin („PBC“ oder „Token“) zum
                    Verkauf an. Die vorliegendenAllgemeinen Geschäftsbedingungen
                    („AGB“) regeln die Geschäftsbeziehung zwischender Paybius GmbH
                    und den Käufer/Kunden für die Transaktionen auf derWebseite{" "}
                    {/* eslint-disable-next-line */}
                    {" "}
                  </p>
                  <h4>B. Vertragspartner</h4>
                  <h5>(1) Der</h5>
                  <p>
                    Paybius GmbH ist geschäftlich tätig im Aufbau von Vertriebswegen
                    und dasBetreiben von Online-Plattformensowie die Entwicklung
                    eines Konzepts zur Erstellung einer Kryptowährung und
                    derTokenisierung.
                  </p>
                  <h5>(2) Die</h5>
                  <p>
                    Paybius GmbH ist geschäftlich tätig im Aufbau von Vertriebswegen
                    und dasBetreiben von Online-Plattformensowie die Entwicklung
                    eines Konzepts zur Erstellung einer Kryptowährung und
                    derTokenisierung.
                  </p>
                  <h4>C. PaybiusCoin („PBC“ oder „Token“)</h4>
                  <h5>(1) Der</h5>
                  <p>
                    Paybius Coin ist ein reiner Zahlungstoken. Er soll dazu dienen,
                    alsRechnungseinheit für den Tausch sowohl mit anderen
                    Kryptowährungen als auch fürden Tausch mit Zentralbankwährungen
                    zu dienen.
                  </p>
                  <h5>(2) Der CB</h5>
                  <p>
                    Cist nicht mit Rechten gegenüber der Paybius GmbH oder gegenüber
                    anderenDritten ausgestattet. Die Paybius GmbH hat keine
                    Rückkaufverpflichtung fürdie Token. Der PBC gewährt dem Inhaber
                    keine Vergütung.
                  </p>
                  <h5>(3) Der CB</h5>
                  <p>
                    Czeichnet sich durch die faktische Zugriffsmöglichkeit über eine
                    kryptographischeWallet und die Übertragbarkeit in der Blockchain
                    (BEP20 Standard) aus.
                  </p>
                  <h5>(4) Die</h5>
                  <p>
                    Paybius GmbH garantiert nicht Art und Umfang der Handelbarkeit
                    des Tokenauf dem Markt. Die Paybius GmbH garantiert keinen
                    bestimmten Wert des PBC.Der Wert des PBC wird ausschließlich
                    durch Angebot und Nachfrage auf dem Marktbestimmt.
                  </p>
                  <h4>D. Vertragsschluss</h4>
                  <h5>(1) Um die</h5>
                  <p>
                    PBC kaufen und erhalten zu können, muss der Kunde über eine
                    kryptographischeWallet verfügen und der Paybius GmbH die
                    kryptographische Adresse derWallet vor Abgabe des Kaufangebots
                    über die Webseite übermitteln. Die kryptographischeAdresse der
                    Wallet muss BEP20 kompatibel sein
                  </p>
                  <h5>(2) Die</h5>
                  <p>
                    Darstellung der Token mit Preisangabe im Online-Shop stellt kein
                    rechtlichbindendes Angebot, sondern nur eine Aufforderung zur
                    Abgabe eines Kaufangebots dar.
                  </p>
                  <h5>(3) Durch</h5>
                  <p>
                    Anklicken des Buttons „Kaufen/Buy“ gibt der Kunde ein
                    verbindliches Kaufangebotüber die auf der Bestellseite gewählten
                    Menge Token gegen Zahlung des auf derWebseite errechneten
                    Preises in der gewählten Währung ab. Der Kaufvertrag
                    kommtzustande, wenn Paybius GmbH die gekauften Token an die
                    kryptographischeAdresse der Wallet des Kunden schickt.
                  </p>
                  <h4>E. Erhebung,Verarbeitung und Nutzung von Daten</h4>
                  <p>
                    DieDatenerhebung und -weitergabe entspricht den Anforderungen
                    der DSGVO und desBDSG. Einzelheiten entnehmen Sie unserer
                    Datenschutzerklärung.
                  </p>
                  <h4>F. Widerrufsrecht</h4>
                  <h5>(1) Wenn Sie</h5>
                  <p>
                    Verbraucher sind, steht Ihnen nach Maßgabe der gesetzlichen
                    Bestimmungen einWiderrufsrecht zu. Verbraucher ist jede
                    natürliche Person, die einRechtsgeschäft zu einem Zweck
                    abschließt, der überwiegend weder ihrergewerblichen noch ihrer
                    selbstständigen beruflichen Tätigkeit zugerechnetwerden kann.
                  </p>
                  <h5>(2) Das</h5>
                  <p>
                    Widerrufsrecht besteht nicht oder nicht mehr, sobald der PBC mit
                    Drittenhandelbar und der Preis des Token von Schwankungen auf
                    dem Markt abhängt, aufdie die Paybius GmbH keinen Einfluss hat,
                    § 312g Abs. 2 Nr. 8 BGB.
                  </p>
                  <h5>(3) Im</h5>
                  <p>
                    Übrigen gelten für das Widerrufsrecht die Regelungen, die im
                    Einzelnenwiedergegeben sind in der folgenden
                  </p>
                  <p>Widerrufsbelehrung</p>
                  <p>Widerrufsrecht</p>
                  <p>
                    Sie habendas Recht, binnen vierzehn Tagen ohne Angabe von
                    Gründen diesen Vertrag zuwiderrufen.
                  </p>
                  <p>
                    DieWiderrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie
                    oder ein von Ihnenbenannter Dritter, der nicht der Beförderer
                    ist, die Waren in Besitz genommenhaben bzw. hat.
                  </p>
                  <p>Um IhrWiderrufsrecht auszuüben, müssen Sie uns</p>
                  <p>Paybius GmbH</p>
                  <p>
                    mittelseiner eindeutigen Erklärung (z.B. ein mit der Post
                    versandter Brief oder eineE-Mail) über Ihren Entschluss, diesen
                    Vertrag zu widerrufen, informieren. Siekönnen dafür das
                    beigefügte Muster-Widerrufsformular verwenden, das jedochnicht
                    vorgeschrieben ist. Sie können das Muster- Widerrufsformular
                    oder eineandere eindeutige Erklärung auch auf unserer Webseite elektronisch ausfüllen und übermitteln. Machen Sie von dieser
                    MöglichkeitGebrauch, so werden wir Ihnen unverzüglich per E-Mail
                    eine Bestätigung über denEingang eines solchen Widerrufs
                    übermitteln.
                  </p>
                  <p>
                    Zur Wahrungder Widerrufsfrist reicht es aus, dass Sie die
                    Mitteilung über die Ausübung desWiderrufsrechts vor Ablauf der
                    Widerrufsfrist absenden.
                  </p>
                  <p>Folgendes Widerrufs</p>
                  <p>
                    Wenn Siediesen Vertrag widerrufen, haben wir Ihnen alle
                    Zahlungen, die wir von Ihnenerhalten haben, einschließlich der,
                    unverzüglich und spätestens binnen vierzehnTagen ab dem Tag
                    zurückzuzahlen, an dem die Mitteilung über Ihren Widerrufdieses
                    Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                    verwenden wirdasselbe Zahlungsmittel, das Sie bei der
                    ursprünglichen Transaktion eingesetzthaben, es sei denn, mit
                    Ihnen wurde ausdrücklich etwas anderes vereinbart; inkeinem Fall
                    werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                    Wirkönnen die Rückzahlung verweigern, bis wir die Token
                    zurückerhalten haben oderbis Sie den Nachweis erbracht haben,
                    dass Sie die Token an unserekryptographische Adresse geschickt
                    haben, je nachdem, welches der frühereZeitpunkt ist.
                  </p>
                  <p>
                    Sie habendie Token unverzüglich und in jedem Fall spätestens
                    binnen vierzehn Tagen abdem Tag, an dem Sie uns über den
                    Widerruf dieses Vertrages unterrichten, an unszurückzusenden
                    oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren
                    vorAblauf der Frist von vierzehn Tagen absenden.
                  </p>
                  <p>
                    Sie tragendie unmittelbaren Kosten der Rücksendung der Waren.
                  </p>
                  <p>
                    Sie müssenfür einen etwaigen Wertverlust der Token nur
                    aufkommen, wenn dieser Wertverlustauf einen zur Prüfung der
                    Beschaffenheit, Eigenschaften und Funktionsweise derWaren nicht
                    notwendigen Umgang mit ihnen zurückzuführen ist.
                  </p>
                  <p>- Ende der Widerrufsbelehrung -</p>
                  <h4>G. Preise</h4>
                  <h5>(1) Der PBC kann gegen Zahlung in</h5>
                  <p>vier Währungen erworben werden:</p>
                  <p>- US-Dollar</p>
                  <p>- Euro</p>
                  <p>- USDCoin (USDC)</p>
                  <p>- BinanceUSD (BUSD)</p>
                  <p>
                    Der aktuelle Preis der PBC wird aufder Webseite zu jeder der
                    vier Währungen angegeben.
                  </p>
                  <h4>Weitere Steuern oder Kosten fallennicht an.</h4>
                  <h5>(2) Die Preise können je nach</h5>
                  <p>
                    Verkaufsphase und Marktbewertung der Kaufwährung variieren. Es
                    gilt der bei derAbgabe des Kaufangebots auf der Webseite für die
                    Kaufwährung angegebene Preis.
                  </p>
                  <h4>H. Lieferung</h4>
                  <h5>(1) Die Paybius GmbH liefert die</h5>
                  <p>
                    PBC durch senden der gekauften Menge PBC an die vom Kunden
                    mitgeteiltekryptographische Adresse seiner Wallet. Erfüllungsort
                    sowie Nacherfüllungsortsind am Sitz des Verkäufers.
                  </p>
                  <h5>(2) Der Verkäufer haftet nicht für</h5>
                  <p>
                    Unmöglichkeit der Lieferung oder für Lieferverzögerungen, soweit
                    diese durchhöhere Gewalt oder sonstige nicht vorhersehbare
                    Ereignisse (z.B.Betriebsstörungen aller Art, Streiks,
                    Aussperrungen, Mangel an Arbeitskräften,Energie oder Rohstoffen,
                    Schwierigkeiten bei der Beschaffung von notwendigenbehördlichen
                    Genehmigungen, behördliche Maßnahmen oder die ausbleibende,
                    nichtrichtige oder nicht rechtzeitige Belieferung durch
                    Lieferanten) verursachtworden sind, die der Verkäufer nicht zu
                    vertreten hat. Die Lieferfristverlängert sich entsprechend der
                    Dauer derartiger Maßnahmen und Hindernisse.Dies gilt auch, wenn
                    diese Umstände bei einem Unterlieferanten eintreten.
                  </p>
                  <h4>I. Zahlung</h4>
                  <p>
                    Die Zahlung erfolgt per Vorkassedurch Übertragung des
                    Kaufpreises in der gewählten Währung auf diekryptographische
                    Adresse der Paybius GmbH.
                  </p>
                  <h4>J. Haftung</h4>
                  <p>
                    In Fälleneiner verschuldensabhängigen Haftung ist die Haftung
                    des Verkäufers auf Vorsatzund grobe Fahrlässigkeit begrenzt. Die
                    Haftungssumme ist auf den Auftragswertbegrenzt. Ist der Käufer
                    privater Endverbraucher, bemisst sich der Auftragswertunter
                    Einbeziehung der Umsatzsteuer, andernfalls bleibt diese
                    unberücksichtigt(Nettopreis). Eine Haftung für entgangenen
                    Gewinn und auf Ersatz vergeblicherAufwendungen ist
                    ausgeschlossen. Diese Haftungsbegrenzung gilt nicht
                    fürSchadensersatzansprüche des Käufers{" "}
                  </p>
                  <p>
                    (i) aus der Verletzung des Lebens, desKörpers oder der
                    Gesundheit, die auf einer fahrlässigen oder
                    vorsätzlichenPflichtverletzung des Verkäufers, seiner
                    gesetzlichen Vertreter oderErfüllungsgehilfen beruhen,{" "}
                  </p>
                  <p>
                    (ii) die von der Haftung nach demProdukthaftungsgesetz umfasst
                    sind{" "}
                  </p>
                  <p>
                    (iii) wegen Fehlens einer vom Verkäuferübernommenen Garantie für
                    die Beschaffenheit der Ware,{" "}
                  </p>
                  <p>
                    (iv) für Schäden, dieauf vorsätzlichen oder grob fahrlässigen
                    Vertragsverletzungen sowie Arglist desVerkäufers, seiner
                    gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen und
                  </p>
                  <p>
                    (v) aus der Verletzung derjenigen Pflichten des Verkäufers, die
                    erfüllt seinmüssen, um die ordnungsgemäße Durchführung des
                    Vertrages überhaupt erst zuermöglichen und auf deren Erfüllung
                    der Käufer regelmäßig vertrauen darf(insbesondere jedoch nicht
                    beschränkt auf die Übergabe und Übereignung derKaufsache), wobei
                    insoweit die Haftung des Verkäufers für einfacheFahrlässigkeit
                    auf die Schäden beschränkt ist, die typischerweise mit
                    demVertrag verbunden und vorhersehbar sind, es sei denn, es
                    handelt sich um Schadensersatzansprüchedes Käufers aus der
                    Verletzung des Lebens, des Körpers oder der Gesundheit.Soweit
                    hierin nicht anders bestimmt, gelten diese Einschränkungen
                    auchzugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen
                    des Verkäufers,wenn Ansprüche direkt gegen diese geltend gemacht
                    werden.
                  </p>
                  <h4>K. Streitbeilegung</h4>
                  <p>
                    DieEU-Kommission hat eine Internetplattform zur Online-Beilegung
                    vonStreitigkeiten geschaffen. Die Plattform dient als
                    Anlaufstelle zuraußergerichtlichen Beilegung von Streitigkeiten
                    betreffend vertraglicheVerpflichtungen, die aus
                    Online-Kaufverträgen erwachsen. Nähere Informationensind unter
                    dem folgenden Link verfügbar:{" "}
                    ZurTeilnahme an einem Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstellesind wir weder bereit noch
                    verpflichtet.
                  </p>
                  {/* <p>
                Hilden, style='font-size:11.0pt;line-height:150%'>
                style='mso-spacerun:yes'> TIME \@ "d. MMMM yyyy"
                style='mso-element:field-separator'>21.Oktober 2022
                line-height:150%'>
              </p> */}
                </>
                :
                <>
                  <p className="first-para">
                    General terms and conditions - Paybius
                  </p>
                  <h4>A. Scope of application</h4>
                  <h5>(1) On the</h5>
                  <p>
                    website, Paybius GmbH ("Paybius GmbH") offers thePaybius Coin ("PBC" or "Token") for sale. These General Terms and Conditions ("GTC") govern the business relationship between Paybius GmbH and the buyer/customer for transactions on the Website.
                  </p>
                  <h4>B. Contractual partner</h4>
                  <h5>(1) The</h5>
                  <p>
                    Paybius GmbH is commercially active in the development of distribution channels and theoperation of online platformsas well as the development of a concept for the creation of a cryptocurrency and theTokenization.
                  </p>
                  <h5>(2) The</h5>
                  <p>
                    Paybius GmbH is engaged in the business of establishing distribution channels and operating online platformsas well as developing a concept for the creation of a cryptocurrency and tokenization.
                  </p>
                  <h4>C. PaybiusCoin ("PBC" or "Token")</h4>
                  <h5>(1) The</h5>
                  <p>
                    Paybius Coin is a pure payment token. It is intended to serve as a unit of account for exchange with both other cryptocurrencies and exchange with central bank currencies.
                  </p>
                  <h5>(2) The CB</h5>
                  <p>
                    is not vested with any rights against Paybius GmbH or against any other Third Party. Paybius GmbH has no repurchase obligation for the tokens. The PBC shall not grant any remuneration to the holder.
                  </p>
                  <h5>(3) The CB</h5>
                  <p>
                    is characterized by the factual accessibility via a cryptographicwallet and the transferability in the blockchain (BEP20 standard).
                  </p>
                  <h5>(4) The</h5>
                  <p>
                    Paybius GmbH does not guarantee the type and scope of the tradability of the Token on the market. Paybius GmbH does not guarantee any particular value of the PBC.The value of the PBC is determined exclusively by supply and demand on the market.
                  </p>
                  <h4>D. Conclusion of the contract</h4>
                  <h5>(1) In order to be able to</h5>
                  <p>
                    PBC, the Customer must have a cryptographic wallet and transmit the cryptographic address of the wallet to Paybius GmbH via the Website before submitting the purchase offer. The cryptographic address of the Wallet must be BEP20 compatible.
                  </p>
                  <h5>(2) The</h5>
                  <p>
                    presentation of the Tokens with price information in the online store shall not constitute a legally binding offer, but only an invitation to submit a purchase offer.
                  </p>
                  <h5>(3) By</h5>
                  <p>
                    By clicking the button "Buy", the customer makes a binding offer to purchase the quantity of Tokens selected on the order page against payment of the price calculated on the website in the selected currency. The purchase agreement shall be concluded when Paybius GmbH sends the purchased tokens to the cryptographic address of the customer's wallet.
                  </p>
                  <h4>E. Collection,processing and use of data</h4>
                  <p>
                    The data collection and disclosure complies with the requirements of the DSGVO and theBDSG. For details, please refer to our privacy policy.
                  </p>
                  <h4>F. Right of withdrawal</h4>
                  <h5>(1) If you are</h5>
                  <p>
                    are a consumer, you have a right of revocation in accordance with the statutory provisions. A consumer is any natural person who enters into a legal transaction for a purpose that is predominantly neither commercial nor self-employed.
                  </p>
                  <h5>(2) The</h5>
                  <p>
                    right of revocation shall not exist or shall cease to exist as soon as the PBC is tradable with third parties and the price of the Token depends on fluctuations in the market over which Paybius GmbH has no influence, § 312g para. 2 no. 8 BGB.
                  </p>
                  <h5>(3) In all other respects</h5>
                  <p>
                    the regulations shall apply to the right of revocation, which are reproduced in detail in the following
                  </p>
                  <p>Cancellation policy</p>
                  <p>Right of withdrawal</p>
                  <p>
                    You have the right to cancel this contract within fourteen days without giving any reason.
                  </p>
                  <p>
                    The revocation period is fourteen days from the day on which you or a third party named by you, who is not the carrier, have taken possession of the goods.
                  </p>
                  <p>In order to exercise your right of revocation, you must send us</p>
                  <p>Paybius GmbH</p>
                  <p>
                    by means of a clear declaration (e.g. a letter sent by mail or an e-mail) about your decision to revoke this contract. You can use the attached model withdrawal form, which is not mandatory. You can also fill out and submit the model withdrawal form or another clear declaration electronically on our website. If you make use of this option, we will immediately send you an e-mail confirming receipt of such a revocation.
                  </p>
                  <p>
                    In order to comply with the cancellation period, it is sufficient that you send the notification of the exercise of the right of cancellation before the expiry of the cancellation period.
                  </p>
                  <p>The following revocation</p>
                  <p>
                    If you withdraw from this contract, we will reimburse you for all payments we have received from you, including the purchase price, without undue delay and at the latest within fourteen days from the day on which we received the notification of your withdrawal from this contract. For this repayment, we will use the same means of payment that you used in the original transaction, unless expressly agreed otherwise with you; in no case will you be charged for this repayment. We may refuse repayment until we have received the Tokens back or until you have provided proof that you have sent the Tokens to our cryptographic address, whichever is earlier.
                  </p>
                  <p>
                    You shall return or hand over the tokens to us without undue delay and in any case no later than fourteen days from the day on which you notify us of the revocation of this contract. The deadline is met if you send the goods before the deadline of fourteen days.
                  </p>
                  <p>
                    You shall bear the direct costs of returning the goods.
                  </p>
                  <p>
                    You only have to pay for a possible loss of value of the tokens if this loss of value is due to a handling of the goods that is not necessary for the examination of the condition, properties and functioning of the goods.
                  </p>
                  <p>- End of the cancellation policy -</p>
                  <h4>G. Prices</h4>
                  <h5>(1) The PBC can be acquired against payment in</h5>
                  <p>four currencies:</p>
                  <p>- US Dollar</p>
                  <p>- Euro</p>
                  <p>- USDCoin (USDC)</p>
                  <p>- BinanceUSD (BUSD)</p>
                  <p>
                    The current price of the PBC is shown on the website for each of the four currencies.
                  </p>
                  <h4>There are no other taxes or costs.</h4>
                  <h5>(2) Prices may vary depending on the</h5>
                  <p>
                    sales phase and market valuation of the purchase currency. The price indicated on the Website for the purchase currency at the time of submitting the purchase offer shall apply.
                  </p>
                  <h4>H. Delivery</h4>
                  <h5>(1) Paybius GmbH shall deliver the</h5>
                  <p>
                    PBC by sending the purchased amount of PBC to the cryptographic address of its Wallet provided by the Customer. The place of performance as well as the place of subsequent performance shall be at the registered office of the Seller.
                  </p>
                  <h5>(2) The Seller shall not be liable for</h5>
                  <p>
                    impossibility of delivery or for delays in delivery, insofar as these are caused by force majeure or other unforeseeable events (e.g. operational disruptions of all kinds, strikes, lockouts, shortages of labor, energy or raw materials, difficulties in obtaining the necessary official permits, official measures or the failure to deliver, incorrect delivery or late delivery by suppliers) for which the Seller is not responsible. The delivery period shall be extended in accordance with the duration of such measures and obstacles. This shall also apply if these circumstances occur at a sub-supplier.
                  </p>
                  <h4>I. Payment</h4>
                  <p>
                    Payment shall be made in advance by transferring the purchase price in the chosen currency to the cryptographic address of Paybius GmbH.
                  </p>
                  <h4>J. Liability</h4>
                  <p>
                    In cases of fault-based liability, the liability of the Seller shall be limited to intent and gross negligence. The liability sum is limited to the order value. If the buyer is a private end user, the order value is calculated including sales tax, otherwise this is not taken into account (net price). Liability for loss of profit and compensation for futile expenses is excluded. This limitation of liability shall not apply to claims for damages of the Buyer
                  </p>
                  <p>
                    (i) arising from injury to life, body or health caused by negligent or intentional breach of duty by the Seller, its legal representatives or vicarious agents,
                  </p>
                  <p>
                    (ii) which are covered by the liability under the Product Liability Act
                  </p>
                  <p>
                  (iii) due to the absence of a guarantee given by the Seller for the quality of the goods,
                  </p>
                  <p>
                  (iv) for damages based on intentional or grossly negligent breaches of contract or fraudulent intent on the part of the Seller, its legal representatives or vicarious agents; and
                  </p>
                  <p>
                  (v) from the breach of those obligations of the Seller which must be fulfilled in order to enable the proper performance of the contract in the first place and on the fulfillment of which the Buyer may regularly rely (in particular, however, not limited to the handover and transfer of ownership of the purchased item), in which case the Seller's liability for ordinary negligence shall be limited to the damage typically associated with the contract and foreseeable, unless it is a matter of claims for damages by the Buyer arising from injury to life, limb or health. Unless otherwise provided herein, these limitations shall also apply to the benefit of Seller's legal representatives and agents if claims are asserted directly against them.
                  </p>
                  <h4>K. Dispute Resolution</h4>
                  <p>
                  The EU Commission has created an Internet platform for the online settlement of disputes. The platform serves as a contact point for the out-of-court settlement of disputes concerning contractual obligations arising from online sales contracts. More information is available at the following link: We are neither willing nor obligated to participate in a dispute resolution procedure before a consumer arbitration board.
                  </p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
