import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "gm",

  resources: {
    en: {
      translation: {
        Wallet: "Crypto Wallet",
        Login: "Login",
        "Sign Up": "Sign Up",
        Dashboard: "Dashboard",
        "Buy Tokens": "Buy Tokens",
        Transactions: "Transactions",
        Withdraw: "Withdraw",
        Profile: "Profile",
        "My Token": "My Token",
        "Main Site": "Main Site",
        "KYC Applications": "KYC Applications",
        Welcome: "Welcome",
        "Every Idea Has To Start": "Every Idea Has To Start",
        Home_intro_line_1:
          "It’s an ever-changing business. To stay on top of the game",
        Home_intro_line_2: "don’t forget to keep yourself in the know",
        "Tokens Balance": "Tokens Balance",
        "Your Contribution": "Your Contribution",
        "Token Name": "Token Name",
        "Token Symbol": "Token Symbol",
        "Download Whitepaper": "Download Whitepaper",
        "Your Account Status": "Your Account Status",
        "Email Verified": "Email Verified",
        "Submit KYC": "Submit KYC",
        "Receiving Wallet": "Receiving Wallet",
        "Add Your Wallet Address": "Add Your Wallet Address",
        "Connect Wallet": "Connect Wallet",
        "Token Sales Progress": "Token Sales Progress",
        RAISED: "RAISED",
        TOTAL: "TOTAL",
        "SALES END IN": "SALES END IN",
        "Token Calculation": "Token Calculation",
        "Enter amount to calculate token.": "Enter amount to calculate token.",
        "Select Token": "Select Token",
        "Amount calculated based on current token price":
          "Amount calculated based on current token price",
        "Sales data not available.": "Sales data not available.",
        FINISHED: "FINISHED",
        "Our token sales has been finished.":
          "Our token sales has been finished.",
        "Thank you very much for your contribution.":
          "Thank you very much for your contribution.",
        "Token Sales Graph": "Token Sales Graph",
        "View All": "View All",
        Amount: "Amount",
        Date: "Date",
        Purchased: "Purchased",
        "Thank you for your interest":
          "Thank you for your interest in our PAYBIUS",
        "You can contribute PBC":
          "You can contribute PBC token go through Buy Token page. You can get a quick response to any questions, and chat with the project in our Telegram: Paybius | D | A | CH Don’t hesitate to invite your friends!",
        "Download a wallet": "Download a wallet",
        "Deposit your receiving address": "Deposit your receiving address",
        "Buy the coins and pay them out":
          "Buy the coins to get them automatically into your account ",
        "Import the following address into your wallet so you can see your coins":
          "Import the following address into your wallet so you can see your coins",
        "How to Buy": "How to Buy",
        FAQs: "FAQs",
        "Privacy Policy": "Privacy Policy",
        "Terms and Conditions": "Terms and Conditions",
        "Install Metamask": "Install Metamask",
        "Install Now": "Install Now",
        "Install Later": "Install Later",
        "No data found!": "No data found!",
        "Choose currency": "Choose currency and calculate PBC token price",
        "You can buy our":
          "You can buy our PBC token using the below currency choices to become part of project.",
        "Amount of Contribution": "Amount of Contribution",
        "Enter the amount you would like":
          "Enter the amount you would like to contribute in order to calculate the amount of tokens you will receive. The calculator below helps to converts the required quantity of tokens into the amount of your select currency.",
        "52.34 BUSD (700 PBC) Minimum contribution amount is required.":
          "52.34 BUSD (700 PBC) Minimum contribution amount is required.",
        "Sale Bonus": "Sale Bonus",
        "Token Sales": "Token Sales",
        "PBC Token Price": "PBC Token Price",
        "Exchange Rate": "Exchange Rate",
        "Your contribution will be calculated":
          "Your contribution will be calculated based on exchange rate at the amount when your transaction is confirmed.",
        "Make Payment": "Make Payment",
        "Token will appear in your account":
          "Token will appear in your account after payment successfully made and approved by our team. Please note that, PBC token will be distributed after the token sale end-date.",
        "You must login to view transactions.":
          "You must login to view transactions.",
        "Unable to fetch data!": "Unable to fetch data!",
        "Not a member?": "Not a member?",
        "Secure Your Communications with Easymail":
          "Secure Your Communications with Easymail",
        "Email Address": "Email Address",
        Password: "Password",
        "Forgot Password": "Forgot Password",
        "Remember Me": "Remember Me",
        OR: "OR",
        "Already a member?": "Already a member?",
        "Full Name": "Full Name",
        "Confirm Password": "Confirm Password",
        "At least 8 characters": "At least 8 characters",
        "At least one number (0-9) or a symbol":
          "At least one number (0-9) or a symbol",
        "Lowercase (a-z) and uppercase (A-Z)":
          "Lowercase (a-z) and uppercase (A-Z)",
        "My PBC Token": "My PBC Token",
        "Token Balance": "Token Balance",
        "Equivalent To": "Equivalent To",
        "Buy More Tokens": "Buy More Tokens",
        "Total Token Amount": "Total Token Amount",
        "Purchased Token": "Purchased Token",
        "Withdraw Your Token": "Withdraw Your Token",
        "You can withdraw your":
          "You Can Withdraw Your Token Any Time, We Will Process Within 24 Hours After Your Withdraw Request.",
        "Withdraw History": "Withdraw History",
        "Identity Verification - KYC": "Identity Verification - KYC",
        "To comply with regulations":
          "To comply with regulations, participant will have to go through identity verification.",
        "You have not submitted":
          "You have not submitted your documents to verify your identity (KYC).",
        "Click to Proceed": "Click to Proceed",
        Logout: "Logout",
        "Disconnect Wallet": "Disconnect Wallet",
        Success: "Success",
        "You have been authenticated":
          "You have been authenticated successfully! Please wait, you are being logged in...",
        "Loading data...": "Loading data...",
        "Transaction No.": "Transaction No.",
        Amount: "Amount",
        "EUR Amount": "EUR Amount",
        To: "To",
        Type: "Type",
        "No transactions found!": "No transactions found!",
        "Profile Details": "Profile Details",
        "Personal Data": "Personal Data",
        "Mobile Number": "Mobile Number",
        "Date of Birth": "Date of Birth",
        Nationality: "Nationality",
        Update: "Update",
        "Security Settings": "Security Settings",
        "Save my activities log": "Save my activities log",
        "Alert me by email":
          "Alert me by email in case of unusual activity in my account",
        "Old Password": "Old Password",
        "New Password": "New Password",
        "Two-Factor Verification": "Two-Factor Verification",
        "Two-factor authentication is a method":
          "Two-factor authentication is a method for protection of your account. When it is activated you are required to enter not only your password, but also a special code. You can receive this code in mobile app. Even if third party gets access to your password, they still won't be able to access your account without the 2FA code.",
        "Enable 2FA": "Enable 2FA",
        "CURRENT STATUS": "CURRENT STATUS",
        DISABLED: "DISABLED",
        "Select Country": "Select Country",
      },
    },

    gm: {
      translation: {
        Wallet: "Krypto-Wallet",
        Login: "Anmeldung",
        "Sign Up": "Anmelden",
        Dashboard: "Dashboard",
        "Buy Tokens": "Token kaufen",
        Transactions: "Transaktionen",
        Withdraw: "Zurückziehen",
        Profile: "Profil",
        "My Token": "Mein Token",
        "Main Site": "Hauptseite",
        "KYC Applications": "KYC-Anwendungen",
        Welcome: "Wilkommen",
        "Every Idea Has To Start": "Paybius Deine neue globale Währung",
        Home_intro_line_1:
          "Paybius nutzt encrypted blockchain technologie, für globale und lokale",
        Home_intro_line_2: "Zahlungen zu tätigen.",
        "Tokens Balance": "Token Bilanz",
        "Your Contribution": "Ihr Beitrag",
        "Token Name": "Token Name",
        "Token Symbol": "Token Symbol",
        "Download Whitepaper": "Whitepaper herunterladen",
        "Your Account Status": "Ihr Kontostatus",
        "Email Verified": "E-Mail verifiziert",
        "Submit KYC": "KYC einreichen",
        "Receiving Wallet": "Empfangene Wallet",
        "Add Your Wallet Address": "Ihre Wallet-Adresse hinzufügen",
        "Connect Wallet": "Wallet verbinden",
        "Token Sales Progress": "Fortschritte beim Token-Verkauf",
        RAISED: "EINGENOMMEN",
        TOTAL: "GESAMT",
        "SALES END IN": "VERKÄUFE ENDEN IN",
        "Token Calculation": "Token-Berechnen",
        "Enter amount to calculate token.":
          "Geben Sie den Betrag für die Berechnung des Tokens ein.",
        "Select Token": "Token auswählen",
        "Amount calculated based on current token price":
          "Auf der Grundlage des aktuellen Token-Preises berechneter Betrag",
        "Sales data not available.": "Verkaufsdaten nicht verfügbar.",
        FINISHED: "BEENDET",
        "Our token sales has been finished.":
          "Unser Token-Verkauf ist beendet.",
        "Thank you very much for your contribution.":
          "Vielen Dank für Ihren Beitrag.",
        "Token Sales Graph": "Token-Verkaufsgrafik",
        "View All": "Alle anzeigen",
        Amount: "Betrag",
        Date: "Datum",
        Purchased: "Gekauft",
        "Thank you for your interest":
          "Vielen Dank für Ihr Interesse an unserem PAYBIUS",
        "You can contribute PBC":
          'Sie können PBC Token kaufen indem Sie auf "Token kaufen" Seite gehen. Um eine schnelle Antwort auf alle Fragen zu erhalten und mit uns zu chatten, gehen  Sie bitte auf unserem Telegram: Paybius | D | A | CH. Zögern Sie nicht, Ihre Freunde einzuladen!',
        "Download a wallet": "Lade dir deine Wallet runter",
        "Deposit your receiving address": "Hinterlege deine Empfangsadresse",
        "Buy the coins and pay them out":
          "Kaufe die Coins, diese werden automatish in dein Konto eingezhalt",
        "Import the following address into your wallet so you can see your coins":
          "Importiere die folgende Adresse in deine Wallet damit du deine Coins siehst",
        "How to Buy": "Wie kaufen?",
        FAQs: "FAQs",
        "Privacy Policy": "Datenschutzrichtlinien",
        "Terms and Conditions": "Bedingungen und Konditionen",
        "Install Metamask": "Metamask installieren",
        "Install Now": "Jetzt installieren",
        "Install Later": "Später installieren",
        "No data found!": "Keine Daten gefunden!",
        "Choose currency": "Währung wählen und PBC-Token-Preis berechnen",
        "You can buy our":
          "Sie können unsere PBC-Token in den unten aufgeführten Währungen kaufen, um Teil des Projekts zu werden.",
        "Amount of Contribution": "Betrag der Spende",
        "Enter the amount you would like":
          "Geben Sie den Betrag ein, den Sie spenden möchten, um die Anzahl der Token zu berechnen, die Sie erhalten. Der Rechner unten hilft Ihnen, die benötigte Menge an Token in die von Ihnen gewählte Währung umzurechnen.",
        "52.34 BUSD (700 PBC) Minimum contribution amount is required.":
          "52,34 BUSD (700 PBC) Der Mindestbeitrag ist erforderlich.",
        "Sale Bonus": "Verkaufsbonus",
        "Token Sales": "Token-Verkauf",
        "PBC Token Price": "PBC-Token-Preis",
        "Exchange Rate": "Wechselkurs",
        "Your contribution will be calculated":
          "Ihr Beitrag wird auf der Grundlage des Wechselkurses berechnet, der zum Zeitpunkt der Bestätigung Ihrer Transaktion gilt.",
        "Make Payment": "Zahlung vornehmen",
        "Token will appear in your account":
          "Die Token werden auf Ihrem Konto erscheinen, nachdem die Zahlung erfolgreich durchgeführt und von unserem Team genehmigt wurde. Bitte beachten Sie, dass die PBC-Token nach dem Enddatum des Token-Verkaufs verteilt werden.",
        "You must login to view transactions.":
          "Sie müssen sich anmelden, um Transaktionen zu sehen.",
        "Unable to fetch data!": "Daten können nicht abgerufen werden!",
        "Not a member?": "Kein Mitglied?",
        "Secure Your Communications with Easymail":
          "Sichern Sie Ihre Kommunikation mit Easymail",
        "Email Address": "E-Mail Adresse",
        Password: "Passwort",
        "Forgot Password": "Passwort vergessen",
        "Remember Me": "Erinnern Sie sich",
        OR: "ODER",
        "Already a member?": "Sie sind bereits Mitglied?",
        "Full Name": "Vollständiger Name",
        "Confirm Password": "Passwort bestätigen",
        "At least 8 characters": "Mindestens 8 Zeichen",
        "At least one number (0-9) or a symbol":
          "Mindestens eine Zahl (0-9) oder ein Symbol",
        "Lowercase (a-z) and uppercase (A-Z)":
          "Kleinbuchstaben (a-z) und Großbuchstaben (A-Z)",
        "Sign In": "Eintragen",
        "My PBC Token": "Mein PBC-Token",
        "Token Balance": "Token-Bilanz",
        "Equivalent To": "Äquivalent zu",
        "Buy More Tokens": "Mehr Token kaufen",
        "Total Token Amount": "Gesamtbetrag der Token",
        "Purchased Token": "Gekaufte Token",
        "Withdraw Your Token": "Token auszahlen lassen",
        "You can withdraw your":
          "Sie können Ihre Token jederzeit abheben, wir werden sie innerhalb von 24 Stunden nach Ihrem Abhebungsantrag bearbeiten.",
        "Withdraw History": "Abhebungshistorie",
        "Identity Verification - KYC": "Identitätsüberprüfung - KYC",
        "To comply with regulations":
          "Um den Vorschriften zu entsprechen, müssen die Teilnehmer eine Identitätsprüfung durchführen.",
        "You have not submitted":
          "Sie haben Ihre Dokumente zur Überprüfung Ihrer Identität (KYC) nicht eingereicht.",
        "Click to Proceed": "Klicken Sie zum Fortfahren",
        Logout: "Abmeldung",
        "Disconnect Wallet": "Wallet abtrennen",
        Success: "Erfolg",
        "You have been authenticated":
          "Sie wurden erfolgreich authentifiziert! Bitte warten Sie, Sie werden eingeloggt...",
        "Loading data...": "Bitte warten...",
        "Transaction No.": "Transaktionsnummer",
        Amount: "Betrag",
        "EUR Amount": "EUR-Betrag",
        To: "An",
        Type: "Typ",
        "No transactions found!": "Keine Transaktionen gefunden!",
        "Profile Details": "Profil-Details",
        "Personal Data": "Persönliche Daten",
        "Mobile Number": "Handynummer",
        "Date of Birth": "Geburtsdatum",
        Nationality: "Nationalität",
        Update: "Aktualisieren",
        "Security Settings": "Sicherheitseinstellungen",
        "Save my activities log": "Logbuch meiner Aktivitäten speichern",
        "Alert me by email":
          "Benachrichtigen Sie mich per E-Mail bei ungewöhnlichen Aktivitäten auf meinem Konto",
        "Old Password": "Altes Passwort",
        "New Password": "Neues Passwort",
        "Two-Factor Verification": "Zwei-Faktoren-Verifizierung",
        "Two-factor authentication is a method":
          "Die Zwei-Faktoren-Authentifizierung ist eine Methode zum Schutz Ihres Kontos. Wenn sie aktiviert ist, müssen Sie nicht nur Ihr Passwort, sondern auch einen speziellen Code eingeben. Diesen Code erhalten Sie in der mobilen App. Selbst wenn eine dritte Person Zugang zu Ihrem Passwort erhält, kann sie ohne den 2FA-Code nicht auf Ihr Konto zugreifen.",
        "Enable 2FA": "2FA aktivieren",
        "CURRENT STATUS": "AKTUELLER STATUS",
        DISABLED: "DISABLED",
        "Select Country": "Land auswählen",
      },
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
