import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseURL } from "../../constants";
import { countries } from "../../countries";
import { Collapse, Button, Select, Switch, QRCode } from "antd";
import Arrow from "../../assets/images/btn-arrow.png";
import "../../assets/scss/_profile.scss";
import Finished from "../../assets/images/modal-im.png";
import File from "../../assets/images/file.png";
import Wallet from "../../assets/images/white-w.png";
import Hand from "../../assets/images/hand.png";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";

const { Panel } = Collapse;
const Profile = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const savedUserInfo = localStorage.getItem("user");
  const {t} = useTranslation();

  const [profileUpdateLoading, setProfileUpdateLoading] = useState("");
  const [preferencesUpdateLoading, setPreferencesUpdateLoading] = useState("");
  const [passwordUpdateLoading, setPasswordUpdateLoading] = useState("");
  const [profileData, setProfileData] = useState();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [country, setCountry] = useState("");
  const [saveActivityLog, setSaveActivityLog] = useState(false);
  const [getNotifications, setGetNotifications] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updateProfileRes, setUpdateProfileRes] = useState({});
  const [updatePasswordRes, setUpdatePasswordRes] = useState({});
  const [updatePreferencesRes, setUpdatePreferencesRes] = useState({});

  const onChange = (key) => {
    console.log(key);
  };
  const checkSwitch = (checked) => {
    console.log(`switch to ${checked}`);
  };

  useEffect(() => {
    if (!token || !savedUserInfo) navigate("/login");
    else {
      if (!profileData) {
        axios
          .get(`${baseURL}/profile`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            console.log("Get profile response", res.data);
            let profileData = res.data.data;
            setProfileData(profileData);
            setFullName(profileData.name);
            setEmail(profileData.email);
            if (profileData.mobile_number)
              setMobileNumber(profileData.mobile_number);
            if (profileData.nationality) setCountry(profileData.nationality);
            if (profileData.dob) setDateOfBirth(profileData.dob);
            if (profileData.get_notifications)
              setGetNotifications(profileData.get_notifications === "on");
            if (profileData.save_activity_log)
              setSaveActivityLog(profileData.save_activity_log === "on");
          })
          .catch((err) => {
            console.log("Get profile error", err.response?.data);
          });
      }
    }
  }, [navigate]);

  const updateProfile = () => {
    setUpdateProfileRes({});
    setProfileUpdateLoading(true);
    let payload = {
      name: fullName,
      mobile_number: mobileNumber,
      dob: dateOfBirth,
      nationality: country,
    };
    axios
      .post(`${baseURL}/update-profile`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Update profile response", res.data);
        setUpdateProfileRes(res.data);
        let savedUserObj = JSON.parse(savedUserInfo);
        savedUserObj.name = res.data.data.name;
        localStorage.setItem('user', JSON.stringify(savedUserObj));

        // Update name in header
        const profileUpdateEvent = new CustomEvent("profileUpdated", {
          detail: {updatedName: res.data.data.name},
          bubbles: true,
          cancelable: true,
          composed: false,
        });
        document
          .querySelector("#header-user-info")
          .dispatchEvent(profileUpdateEvent);
      })
      .catch((err) => {
        console.log("Update profile error", err.response?.data);
        if (typeof err.response?.data?.message === "string")
          setUpdateProfileRes(err.response?.data);
        else setUpdateProfileRes({ message: "An unexpected error occurred" });
      })
      .finally(() => setProfileUpdateLoading(false));
  };

  const updatePreferences = () => {
    setUpdatePreferencesRes({});
    setPreferencesUpdateLoading(true);
    let payload = {
      get_notifications: getNotifications ? "on" : "off",
      save_activity_log: saveActivityLog ? "on" : "off",
    };
    axios
      .post(`${baseURL}/update-security`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Update security response", res.data);
        setUpdatePreferencesRes(res.data);
      })
      .catch((err) => {
        console.log("Update security error", err.response?.data);
        if (typeof err.response?.data?.message === "string")
          setUpdatePreferencesRes(err.response?.data);
        else
          setUpdatePreferencesRes({ message: "An unexpected error occurred" });
      })
      .finally(() => setPreferencesUpdateLoading(false));
  };

  const updatePassword = () => {
    if (newPassword !== confirmPassword) {
      setUpdatePasswordRes({
        message: "New password and confirm password do not match",
      });
      return;
    }
    setUpdatePasswordRes({});
    setPasswordUpdateLoading(true);
    let payload = {
      current_password: oldPassword,
      password: newPassword,
    };
    axios
      .post(`${baseURL}/change-password`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Change password response", res.data);
        setUpdatePasswordRes({
          status: 200,
          message: "Password updated successfully",
        });
      })
      .catch((err) => {
        console.log("Change password error", err.response?.data);
        if (typeof err.response?.data === "string")
          setUpdatePasswordRes(err.response.data);
        else setUpdatePasswordRes({ message: "An unexpected error occurred" });
      })
      .finally(() => setPasswordUpdateLoading(false));
  };

  return (
    <div className="profile">
      <ConnectWallet />
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="profile-cont">
              <h4>{t("Profile Details")}</h4>
              <Collapse defaultActiveKey={["1"]} onChange={onChange}>
                <Panel header={t("Personal Data")} key="1">
                  <div className="profile-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("Full Name")}</label>
                          <input
                            className="form-control"
                            placeholder={t("Full Name")}
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("Email Address")}</label>
                          <input
                            className="form-control"
                            placeholder="example@domain.com"
                            type="text"
                            value={email}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("Mobile Number")}</label>
                          <input
                            className="form-control"
                            placeholder="+12 112345 678"
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("Date of Birth")}</label>
                          <input
                            className="form-control"
                            placeholder="DD-MM-YYYY"
                            type="date"
                            onChange={(e) => setDateOfBirth(e.target.value)}
                            value={dateOfBirth}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>{t("Nationality")}</label>
                          <Select
                            style={{ width: "100%" }}
                            placeholder={t("Select Country")}
                            value={country}
                            onChange={(selected) => setCountry(selected)}
                            options={countries.map((country) => ({
                              label: country.name,
                              value: country.name,
                            }))}
                          ></Select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {updateProfileRes && (
                          <p
                            className={
                              updateProfileRes.status === 200
                                ? "success"
                                : "error"
                            }
                          >
                            {updateProfileRes.message}
                          </p>
                        )}
                        <Button
                          className="update-btn ml-0"
                          disabled={profileUpdateLoading}
                          onClick={updateProfile}
                        >
                          {profileUpdateLoading ? (
                            <div className="spinner-border" />
                          ) : (
                            t("Update")
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header={t("Security Settings")} key="2">
                  <div className="security">
                    <div className="switch">
                      <Switch
                        checked={saveActivityLog}
                        onChange={setSaveActivityLog}
                      />
                      {t("Save my activities log")}
                    </div>
                    <div className="switch">
                      <Switch
                        checked={getNotifications}
                        onChange={setGetNotifications}
                      />
                      {t("Alert me by email")}
                    </div>
                  </div>
                  {updatePreferencesRes && (
                    <p
                      className={
                        "mt-4 " +
                        (updatePreferencesRes.status === 200
                          ? "success"
                          : "error")
                      }
                    >
                      {updatePreferencesRes.message}
                    </p>
                  )}
                  <Button
                    className="update-btn ml-0"
                    disabled={preferencesUpdateLoading}
                    onClick={updatePreferences}
                  >
                    {preferencesUpdateLoading ? (
                      <div className="spinner-border" />
                    ) : (
                      t("Update")
                    )}
                  </Button>
                </Panel>
                <Panel header={t("Password")} key="3">
                  <div className="profile-form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>{t("Old Password")}</label>
                          <input
                            className="form-control"
                            placeholder="***********"
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("New Password")}</label>
                          <input
                            className="form-control"
                            placeholder="***********"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{t("Confirm Password")}</label>
                          <input
                            className="form-control"
                            placeholder="***********"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {updatePasswordRes && (
                          <p
                            className={
                              updatePasswordRes.status === 200
                                ? "success"
                                : "error"
                            }
                          >
                            {updatePasswordRes.message}
                          </p>
                        )}
                        <Button
                          className="update-btn ml-0"
                          disabled={
                            passwordUpdateLoading ||
                            !oldPassword ||
                            !newPassword ||
                            !confirmPassword
                          }
                          onClick={updatePassword}
                        >
                          {passwordUpdateLoading ? (
                            <div className="spinner-border" />
                          ) : (
                            t("Update")
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Account w-100">
              <h4>{t("Your Account Status")}</h4>
              <Button>{t("Email Verified")}</Button>
              <Button
                className="kyc-button"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                {t("Submit KYC")}
              </Button>
              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body">
                      <div className="kyc-modal-cont">
                        <img
                          className="kyc-img"
                          src={Finished}
                          alt="finished"
                        />
                        <h5>KYC Verification</h5>
                        <p>
                          To comply with regulations each participant is
                          required to go through identity verification (KYC/AML)
                          to prevent fraud, money laundering operations,
                          transactions banned under the sanctions regime or
                          those which fund terrorism. Please, complete our fast
                          and secure verification process to participate in
                          token offerings.
                        </p>
                        <div className="file-inner">
                          <img
                            className="mx-auto d-block"
                            src={File}
                            alt="file"
                          />
                          <h4>
                            You have not submitted your necessary documents
                            <span className="br-block"></span> to verify your
                            identity.
                          </h4>
                          <p className="text-center">
                            It would great if you please submit the form. If you
                            have any question,
                            <span className="br-block"></span> please feel free
                            to contact our support team.
                          </p>
                          <Button>Click here to complete your KYC</Button>
                        </div>
                        <p className="text-center">
                          Contact our support team via email -{" "}
                          <a href="mailto:support@Paybius.de">
                            support@Paybius.de
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="wallet-head">{t("Receiving Wallet")}</h4>
              <div className="wallet">
                <div>
                  <p>{t("Add Your Wallet Address")}</p>
                </div>
                <Button className="modal-btn">
                  <img src={Wallet} alt="wallet" />
                  {t("Connect Wallet")}
                </Button>
              </div>
            </div>
            <div className="Account w-100 verification">
              <h4>{t("Identity Verification - KYC")}</h4>
              <p>
                {t("To comply with regulations")}
              </p>
              <h6>
                {t("You have not submitted")}
              </h6>
              <Button className="mt-3">{t("Click to Proceed")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="two-factor">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="two-factor-cont">
                <h4>{t("Two-Factor Verification")}</h4>
                <p>
                  {t("Two-factor authentication is a method")}
                </p>
                <div className="two-factor-status">
                  <Button data-toggle="modal" data-target="#fa-modal">
                    {t("Enable 2FA")}
                  </Button>

                  <div>
                    <h5>{t("CURRENT STATUS")}:</h5>
                    <div className="disable">{t("DISABLED")}</div>
                  </div>
                </div>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="fa-modal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <div className="modal-body">
                        <div className="fa-modal-cont">
                          <h5>Enable 2FA Authentication</h5>
                          <p>
                            Step 1: Install this app from &nbsp;
                            <span>Google Play</span> store or &nbsp;
                            <span>App Store</span>.
                          </p>
                          <p>
                            Step 2: Scan the below QR code by your Google
                            Authenticator app, or you can add account manually.
                          </p>
                          <p>Manually add Account:</p>
                          <p>
                            Account Name: <span>PAYBIUS</span>
                          </p>
                          <p>
                            Key: <span>Q3HIREOXBLFARDTD</span>
                          </p>
                          <div className="fa-qr">
                            <div className="fa-qr-cont">
                              <QRCode value="https://google.com/" />
                            </div>
                            <div className="fa-qr-input">
                              <h6>Enter Google Authenticator Code</h6>
                              <input
                                type="number"
                                placeholder="Enter the code to verify"
                                className="form-control"
                              />
                              <Button>Confirm 2FA</Button>
                            </div>
                          </div>
                          <p className="orange-text">
                            Note: If you lost your phone or uninstall the Google
                            Authenticator app, then you will lost access of your
                            account.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <img className="hand-img" src={Hand} alt="hand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
