import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../assets/scss/_profile.scss";
import Finished from "../../assets/images/modal-im.png";
import File from "../../assets/images/file.png";
import Wallet from "../../assets/images/white-w.png";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";

const MyToken = () => {
  const {t} = useTranslation();

  return (
    <div className="profile">
      <ConnectWallet />
      <div className="container">
        <h4 className="token-head">{t("My PBC Token")}</h4>
        <div className="row">
          <div className="col-md-8">
            <div className="profile-cont token-cont">
              <div className="withdraw">
                <div>
                  <p>{t("Token Balance")}</p>
                  <h3>
                    120,000,000 <small>PBC</small>
                  </h3>
                  <h4>
                    {t("Equivalent To")} <strong>0 EUR</strong>
                  </h4>
                </div>
                <Button>{t("Buy More Tokens")}</Button>
              </div>
              <div className="withdraw">
                <div>
                  <p>{t("Total Token Amount")}</p>
                  <h3>
                    120,000,000 <small>PBC</small>
                  </h3>
                </div>
                <div>
                  <p>{t("Purchased Token")}</p>
                  <h3>
                    0 <small>PBC</small>
                  </h3>
                </div>
              </div>
              <div className="withdraw">
                <div>
                  <p>{t("Total Token Amount")}</p>
                  <h3>0 EUR</h3>
                </div>
              </div>
              <div className="withdraw withdraw-token">
                <div>
                  <h6>{t("Withdraw Your Token")}</h6>
                  <p>
                    {t("You can withdraw your")}
                  </p>
                  <Link to="/#">{t("Withdraw History")} {">"}</Link>
                </div>
                <Button>{t("Withdraw")}</Button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Account w-100">
              <h4>{t("Your Account Status")}</h4>
              <Button>{t("Email Verified")}</Button>
              <Button
                className="kyc-button"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                {t("Submit KYC")}
              </Button>
              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body">
                      <div className="kyc-modal-cont">
                        <img
                          className="kyc-img"
                          src={Finished}
                          alt="finished"
                        />
                        <h5>{("KYC Verification")}</h5>
                        <p>
                          To comply with regulations each participant is
                          required to go through identity verification (KYC/AML)
                          to prevent fraud, money laundering operations,
                          transactions banned under the sanctions regime or
                          those which fund terrorism. Please, complete our fast
                          and secure verification process to participate in
                          token offerings.
                        </p>
                        <div className="file-inner">
                          <img
                            className="mx-auto d-block"
                            src={File}
                            alt="file"
                          />
                          <h4>
                            You have not submitted your necessary documents
                            <span className="br-block"></span> to verify your
                            identity.
                          </h4>
                          <p className="text-center">
                            It would great if you please submit the form. If you
                            have any question,
                            <span className="br-block"></span> please feel free
                            to contact our support team.
                          </p>
                          <Button>Click here to complete your KYC</Button>
                        </div>
                        <p className="text-center">
                          Contact our support team via email -{" "}
                          <a href="mailto:support@Paybius.de">
                            support@Paybius.de
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="wallet-head">{t("Receiving Wallet")}</h4>
              <div className="wallet">
                <div>
                  <p>{t("Add Your Wallet Address")}</p>
                </div>
                <Button className="modal-btn">
                  <img src={Wallet} alt="wallet" />
                  {t("Connect Wallet")}
                </Button>
              </div>
            </div>
            <div className="Account w-100 verification">
              <h4>{t("Identity Verification - KYC")}</h4>
              <p>
               {t("To comply with regulations")}
              </p>
              <h6>
                {t("You have not submitted")}
              </h6>
              <Button className="mt-3">{t("Click to Proceed")}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyToken;
