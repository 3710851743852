import React, { useEffect, useState } from "react";
import "../../assets/scss/_login.scss";
import { Button, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { baseURL } from "../../constants";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import Google from "../../assets/images/google.png";
import Facebook from "../../assets/images/facebook.png";
import Banner from "../../assets/images/login-img.png";
import Arrow from "../../assets/images/arrow2.png";
import Show from "../../assets/images/show.svg";
import Hide from "../../assets/images/hide.svg";
import BtnArrow from "../../assets/images/btn-arrow.png";

const ForgotPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const resetPassword = () => {
    setError("");
    setLoading(true);
    if (step === 1) {
      axios
        .post(`${baseURL}/get-reset-code`, { email })
        .then(res => {
          console.log('Get Reset Code Response', res.data);
          setStep(2);
        })
        .catch(err => {
          console.log(err.response?.data);
          if (err.response?.data?.message === "Invalid Password Or Email Address")
            setError("Invalid email address or password");
          else if (typeof err.response?.data?.message === "string")
            setError(err.response.data.message)
          else
            setError("An error occurred while submitting your data.");

        })
        .finally(() => setLoading(false));
    } else if (step === 2) {
      axios
        .post(`${baseURL}/verify-reset-code`, { code: resetCode })
        .then(res => {
          console.log('Verify Reset Code Response', res.data);
          setStep(3);
        })
        .catch(err => {
          console.log(err.response?.data);
          setError(err.response?.data?.message || "An error occurred while submitting your data.");

        })
        .finally(() => setLoading(false));
    } else {
      if (password !== confirmPassword) {
        setError("Passwords do not match.");
        setLoading(false);
        return;
      }
      axios
        .post(`${baseURL}/reset-pwd`, { code: resetCode, password })
        .then(res => {
          console.log('Reset Password Response', res.data);
          setSuccess(true);
        })
        .catch(err => {
          console.log(err.response?.data);
          setError(err.response?.data?.message || "An error occurred while submitting your data.");

        })
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token'))
      navigate('/profile');
  }, [navigate]);

  return (
    <>
      <div className="login-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              {success ?
                <div className="form-cont">
                  <h3 className="mt-2">Success</h3>
                  <p className="my-5">Your password has been updated successfully! You can proceed to the login page to login with your new password.</p>
                  <Link to="/login">
                    <Button className="login-btn">
                      Login
                    </Button>
                  </Link>
                </div>
                :
                <div className="form-cont">
                  <div className="login-header">
                    <Link to="/#">
                      <img src={Arrow} alt="arrow" />
                    </Link>
                    <p>
                      Not a Member? <Link to="/sign-up">Sign Up</Link>
                    </p>
                  </div>
                  <h3>Forgot Password</h3>
                  <p>
                    {step === 1 ?
                      "Please enter the email address associated with your account."
                      :
                      step === 2 ?
                        "We have sent you an email containing a reset code. Please enter this code below."
                        :
                        "Please enter your new password."
                    }
                  </p>
                  <form>
                    {step === 1 ?
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Email Address"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          autoComplete="none"
                        />
                      </div>
                      :
                      step == 2 ?
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Reset Code*</label>
                          <input
                            className="form-control"
                            aria-describedby="resetCodeHelp"
                            placeholder="Reset Code"
                            value={resetCode}
                            onChange={e => setResetCode(e.target.value)}
                            autoComplete="none"
                          />
                        </div>
                        :
                        <>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">New Password*</label>
                            <div className="password-input">
                              <input
                                type={passwordShown ? "text" : "password"}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="*******"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                autoComplete="none"
                              />
                              <button type="button" onClick={togglePassword}>
                                {passwordShown ? (
                                  <img src={Hide} alt="hide" />
                                ) : (
                                  <img src={Show} alt="show" />
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password*</label>
                            <div className="password-input">
                              <input
                                type={passwordShown ? "text" : "password"}
                                className="form-control"
                                id="exampleInputPassword2"
                                placeholder="*******"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                autoComplete="none"
                              />
                              <button type="button" onClick={togglePassword}>
                                {passwordShown ? (
                                  <img src={Hide} alt="hide" />
                                ) : (
                                  <img src={Show} alt="show" />
                                )}
                              </button>
                            </div>
                          </div>
                        </>
                    }
                  </form>
                  <p className="error my-4">{error}</p>
                  <div className="login-btns">
                    <Button
                      className="login-btn"
                      onClick={resetPassword}
                      disabled={loading || (step === 1 && !email) || (step === 2 && !resetCode) || (step === 3 && (!password || !confirmPassword))}
                    >
                      {loading ?
                        <div className="spinner-border" />
                        :
                        <>
                          Submit <img src={BtnArrow} alt="arrow" />
                        </>
                      }
                    </Button>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid ml-auto d-block"
                src={Banner}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
