import React from "react";
import "../../assets/scss/_login.scss";
import { Button } from "antd";
import Banner from "../../assets/images/login-img.png";

const FaLog = () => {
  return (
    <>
      <div className="login-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="form-cont fa-form">
                <h4>2FA Authentication</h4>
                <p>Hello GAP,</p>
                <p>Enter the verification code generated by your mobile</p>
                <p>application (Google Authenticator)</p>
                <form>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter 6 Digit Code"
                    />
                  </div>
                </form>
                <div className="login-btns">
                  <Button className="login-btn">Sign In</Button>
                </div>
                <p className="mb-0">
                  If you lost your phone or Uninstall the Google Authenticator
                  app and enable to access your account please contact with us.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid ml-auto d-block"
                src={Banner}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaLog;
