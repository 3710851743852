import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { baseURL, formatAmount } from "../../constants";
import "../../assets/scss/_banner.scss";
import Logo from "../../assets/images/curr.png";
import LogoSM from "../../assets/images/logo-ico-new.png";
import Download from "../../assets/images/download.png";
import Ok from "../../assets/images/ok.png";
import Not from "../../assets/images/not.png";
import Empty from "../../assets/images/empty.png";
import Invest from "../../assets/images/invest.png";
import I from "../../assets/images/i.png";
import Graph from "../../assets/images/graph.png";
import Finished from "../../assets/images/modal-im.png";
import File from "../../assets/images/file.png";
import Coin from "../../assets/images/coins.png";
import Wing from "../../assets/images/wing.png";
import Buy from "../../assets/images/buy.png";
import Euro from "../../assets/images/euro-s.png";
import { Button, Select, Slider } from "antd";
import { useTimer } from "react-timer-hook";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import TransactionResponseModal from "../../components/TransactionResponseModal/TransactionResponseModal";

const CountDownTimer = ({ expiryTimestamp }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn("onExpire called");
    },
  });

  const twoDigits = (num) => {
    if (num < 10) return "0" + String(num);
    else return String(num);
  };

  return (
    <div className="timer">
      <div>
        <h3>{twoDigits(days)}</h3>
        <p>DAYS</p>
      </div>
      <div>
        <h3>{twoDigits(hours)}</h3>
        <p>HOURS</p>
      </div>
      <div>
        <h3>{twoDigits(minutes)}</h3>
        <p>MIN</p>
      </div>
      <div>
        <h3>{twoDigits(seconds)}</h3>
        <p>SEC</p>
      </div>
    </div>
  );
}

const Homepage = (props) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const [option, setOption] = useState([
    {
      value: "1",
      label: "ETH",
    },
    {
      value: "2",
      label: "BTC",
    },
    {
      value: "3",
      label: "LTC",
    },
  ]);
  const [inputAmount, setInputAmount] = useState();
  const [outputAmount, setOutputAmount] = useState();
  const [transactionData, setTransactionData] = useState();
  const [tokenSaleProgress, setTokenSaleProgress] = useState();
  const oneTime = useRef(false);
  const saleExpiryDate = Date.parse("2023-08-06T23:59");

  // Chart state
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "token-sales",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    // xaxis: {
    //   categories: ['3 Apr', '4 Apr', '5 Apr', '6 Apr', '7 Apr', '8 Apr']
    // },
    dataLabels: {
      enabled: false,
    }
  });
  const [chartData, setChartData] = useState([
    {
      // name: "Weekly Token Sales",
      // data: [30, 40, 45, 50, 49, 60]
    }
  ]);

  useEffect(() => {
    axios
      .get(`${baseURL}/all-transactions`)
      .then(res => {
        // console.log('Transactions', res.data);
        setTransactionData(res.data?.data);
      })
      .catch(err => {
        // setError(true);
        console.log(err.response?.data);
      })
    axios
      .get(`${baseURL}/token_sale_progress`)
      .then(res => {
        console.log('Token Sale Progress', res.data);
        setTokenSaleProgress(res.data?.data);
      })
      .catch(err => {
        // setError(true);
        console.log(err.response?.data);
      })
    axios
      .get(`${baseURL}/token_sale_graph`)
      .then(res => {
        console.log('Token Sale Graph', res.data);
        setChartOptions({ ...chartOptions, xaxis: { categories: res.data.days } });
        setChartData([{
          name: "Weekly Token Sales",
          data: res.data.data
        }]);
      })
      .catch(err => {
        // setError(true);
        console.log(err.response?.data);
      })
  }, [oneTime])

  return (
    <div>
      <TransactionResponseModal />
      <div className="home">
        <div className="container">
          <div className="home-banner">
            <div className="row">
              <div className="col-md-8">
                <div className="initial-coin">
                  <h3>Initial Coin Offering</h3>
                  <h4>{t("Every Idea Has To Start")}</h4>
                  <p>
                    {t("Home_intro_line_1")}
                    <span className="br-block"></span>
                    {t("Home_intro_line_2")}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="token">
                  <img
                    src={Logo}
                    alt="logo"
                    className="img-fluid mx-auto d-block"
                  />
                  <h4>
                    <small>{t("Tokens Balance")} </small>{" "}
                    <span className="br-block"></span> 120,000,000{" "}
                    <small>PBC</small>
                  </h4>
                  <div className="cotribution">
                    <p>{t("Your Contribution")}</p>
                    <div className="contribution-cont">
                      <div>
                        <h6>2.645</h6>
                        <p>ETH</p>
                      </div>
                      <div>
                        <h6>2.645</h6>
                        <p>BTC</p>
                      </div>
                      <div>
                        <h6>2.645</h6>
                        <p>LTC</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grey-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="ether">
                  <img src={Logo} alt="logo" className="img-fluid" />
                  <h5>1 ETH = 1000 PBC</h5>
                  <p>1 ETH = 254.05 USD</p>
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="whitepaper">
                  <p>
                    {t("Token Name")}:
                    <span>Paybius</span>
                  </p>
                  <p>
                    {t("Token Symbol")}:
                    <span>PBC</span>
                  </p>
                  <Button>
                    {" "}
                    <img src={Download} alt="download" />
                    {t("Download Whitepaper")}
                  </Button>
                </div>
              </div>
              <div className="col-md-6">
                <img className="wing-img img-fluid" src={Wing} alt="wing" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="account-sec">
            <div className="row">
              <div className="col-md-4">
                <div className="Account ">
                  <h4>{t("Your Account Status")}</h4>
                  <Button>{t("Email Verified")}</Button>
                  <Button
                    className="kyc-button"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                  >
                    {t("Submit KYC")}
                  </Button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <div className="modal-body">
                          <div className="kyc-modal-cont">
                            <img
                              className="kyc-img"
                              src={Finished}
                              alt="finished"
                            />
                            <h5>KYC Verification</h5>
                            <p>
                              To comply with regulations each participant is
                              required to go through identity verification
                              (KYC/AML) to prevent fraud, money laundering
                              operations, transactions banned under the
                              sanctions regime or those which fund terrorism.
                              Please, complete our fast and secure verification
                              process to participate in token offerings.
                            </p>
                            <div className="file-inner">
                              <img
                                className="mx-auto d-block"
                                src={File}
                                alt="file"
                              />
                              <h4>
                                You have not submitted your necessary documents
                                to verify your identity.
                              </h4>
                              <p className="text-center">
                                It would great if you please submit the form. If
                                you have any question,
                                <span className="br-block"></span> please feel
                                free to contact our support team.
                              </p>
                              <Button>Click here to complete your KYC</Button>
                            </div>
                            <p className="text-center">
                              Contact our support team via email -{" "}
                              <a href="mailto:support@Paybius.de">
                                support@Paybius.de
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="wallet-head">{t("Receiving Wallet")}</h4>
                  <div className="wallet">
                    <div>
                      <p>{t("Add Your Wallet Address")}</p>
                    </div>
                    <ConnectWallet alternateDesign={true} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="Progress">
                  <h4>{t("Token Sales Progress")}</h4>
                  {tokenSaleProgress ?
                    <div className="progress-slider">
                      <div className="slider-header">
                        <div>
                          <h6>{t("RAISED")}</h6>
                          <p>{parseInt(tokenSaleProgress.sold).toLocaleString()} PBC</p>
                        </div>
                        <div>
                          <h6>{t("TOTAL")}</h6>
                          <p>{parseInt(tokenSaleProgress.total).toLocaleString()} PBC</p>
                        </div>
                      </div>
                      <Slider disabled defaultValue={tokenSaleProgress.sold} max={tokenSaleProgress.total} />
                      <div className="soft-cap">
                        <p>SOFT CAP</p>
                        <p>40,000</p>
                      </div>
                      <div className="hard-cap">
                        <p>HARD CAP</p>
                        <p>1,400,000</p>
                      </div>
                    </div>
                    :
                    <p>{t("Sales data not available.")}</p>
                  }
                  <h6>{t("SALES END IN")}</h6>
                  <CountDownTimer expiryTimestamp={saleExpiryDate} />
                </div>
              </div>
              <div className="col-md-4 d-flex">
                <div className="calculation w-100">
                  <h4>{t("Token Calculation")}</h4>

                  <h6>{t("Enter amount to calculate token.")}</h6>
                  <div className="input-select">
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder={t("Select Token")}
                        type="number"
                        value={inputAmount}
                        onChange={e => {
                          setInputAmount(e.target.value);
                          setOutputAmount(e.target.value / 0.07);
                        }}
                      />
                      <Select
                        showSearch
                        style={{
                          width: 80,
                        }}
                        defaultValue="1"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={option}
                      />
                    </div>
                    <div className="equals">=</div>
                    <div className="price">
                      <h5>{formatAmount(outputAmount)}</h5>
                      <p>PBC</p>
                    </div>
                  </div>
                  <p>
                    <img src={I} alt="i" />
                    {t("Amount calculated based on current token price")}
                  </p>
                  <Button
                    className="modal-btn"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <img src={Buy} alt="buy-token" />
                    {t("Buy Tokens")}
                  </Button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <div className="modal-body">
                          <div className="modal-cont">
                            <img src={Finished} alt="finished" />
                            <div>
                              <h5>{t("FINISHED")}</h5>
                              <p>
                                {t("Our token sales has been finished.")}
                                <span className="br-block"></span>
                                {t("Thank you very much for your contribution.")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="Charts w-100">
                  <div className="charts-header">
                    <h4>
                      <img className="img-fluid" src={LogoSM} alt="logo" />
                      {t("Token Sales Graph")}
                    </h4>
                  </div>
                  {/* <img className="img-fluid" src={Graph} alt="graph" /> */}
                  {chartData[0]?.data ?
                    <Chart
                      options={chartOptions}
                      series={chartData}
                      type="area"
                      width="100%"
                      height="300"
                    />
                    :
                    <p className="text-center my-5">{t("Sales data not available.")}</p>
                  }
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="Charts w-100">
                  <div className="charts-header">
                    <h4>
                      <img src={LogoSM} alt="logo" />
                      {t("Transactions")}
                    </h4>
                    <Link to="/transactions">{t("View All")}</Link>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped">
                      <thead>
                        <tr>
                          <th scope="col">TWZ TOKENS</th>
                          <th scope="col">{t("Amount")}</th>
                          <th scope="col">{t("Date")}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionData?.length > 0 &&
                          transactionData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p className="mb-0">
                                  {" "}
                                  <img src={Ok} alt="mark" />
                                  {formatAmount(item.token)}
                                </p>
                              </td>
                              <td>
                                {formatAmount(item.amount)}
                                <span>
                                  ETH <img src={I} alt="mark" />
                                </span>
                              </td>
                              <td>{new Date(item.created_at).toLocaleString()}</td>
                              <td>
                                <p className="purchase">{item.type}</p>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    {(!transactionData || transactionData.length === 0) && <p className="text-center">{t("No data found!")}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="thanks-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="coins">
                  <img className="coins-img" src={Coin} alt="coins" />
                  <img
                    className="img-fluid symbol-img"
                    src={Euro}
                    alt="euro-s"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="thanks-cont">
                  <h5>{t("Thank you for your interest")}</h5>
                  <p>
                    {t("You can contribute PBC")}
                  </p>
                  <ol>
                    <li>{t("Download a wallet")} <a href="https://metamask.io">https://metamask.io</a></li>
                    <li>{t("Deposit your receiving address")}</li>
                    <li>{t("Buy the coins and pay them out")}</li>
                    <li>
                      {t("Import the following address into your wallet so you can see your coins")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
