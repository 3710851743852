import React, { useEffect, useState } from "react";
import "../../assets/scss/_login.scss";
import { Button, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { baseURL, emailRegex } from "../../constants";
import FacebookLogin from '@greatsumini/react-facebook-login';

import Google from "../../assets/images/google.png";
import Facebook from "../../assets/images/facebook.png";
import Banner from "../../assets/images/login-img.png";
import Arrow from "../../assets/images/arrow2.png";
import Show from "../../assets/images/show.svg";
import Hide from "../../assets/images/hide.svg";
import BtnArrow from "../../assets/images/btn-arrow.png";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const {t} = useTranslation();
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const login = () => {
    let payload = {
      email: userID,
      password,
    }
    callLoginAPI(payload);
  }

  const callLoginAPI = (payload) => {
    if (!emailRegex.test(payload.email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");
    setLoading(true);
    console.log('Payload', payload);

    axios
      .post(`${baseURL}/login`, payload)
      .then(res => {
        console.log('Login Response', res.data);
        if (res.data.status === 200) {
          setSuccess(true);
          localStorage.setItem('token', res.data.access_token);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          if (!rememberMe) {
            let expiryTime = new Date();
            expiryTime.setHours(expiryTime.getHours() + 1);
            localStorage.setItem('expiryTime', expiryTime.toString());
          }
          setTimeout(() => {
            navigate('/');
          }, 3000);
          
        }
      })
      .catch(err => {
        console.log(err.response?.data);
        if (err.response?.data?.message === "Invalid Password Or Email Address")
          setError("Invalid email address or password");
        else
          setError("An error occurred while logging in.");

      })
      .finally(() => setLoading(false))
  }

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log('Token Response', tokenResponse);
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`
            }
          })
        .then(res => {
          console.log('Google API res', res.data);
          let payload = {
            email: res.data.email,
            login_plateform: 'Gmail',
            login_plateform_id: res.data.sub,
            password: res.data.sub,
          }
          callLoginAPI(payload);
        })
        .catch(err => {
          console.log(err);
        })
    },
    onError: errorResponse => {
      console.log('Google Login Error', errorResponse);
    },
  });

  useEffect(() => {
    if (localStorage.getItem('token'))
      navigate('/profile');
  }, [navigate]);

  return (
    <>
      <div className="login-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              {success ?
                <div className="form-cont">
                  <h3 className="mt-2">{t("Success")}</h3>
                  <p className="my-4">{t("You have been authenticated")}</p>
                </div>
                :
                <div className="form-cont">
                  <div className="login-header">
                    <Link to="/#">
                      <img src={Arrow} alt="arrow" />
                    </Link>
                    <p>
                      {t("Not a member?")} <Link to="/sign-up">{t("Sign Up")}</Link>
                    </p>
                  </div>
                  <h3>{t("Sign In")}</h3>
                  <p>{t("Secure Your Communications with Easymail")}</p>
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("Email Address")}*</label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder={t("Email Address")}
                        value={userID}
                        onChange={e => setUserID(e.target.value)}
                        autoComplete="none"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">{t("Password")}*</label>
                      <div className="password-input">
                        <input
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="*******"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          autoComplete="none"
                        />
                        <button type="button" onClick={togglePassword}>
                          {passwordShown ? (
                            <img src={Hide} alt="hide" />
                          ) : (
                            <img src={Show} alt="show" />
                          )}
                        </button>
                      </div>
                    </div>
                    <Link className="forgot" to="/forgot-password">
                      {t("Forgot Password")}
                    </Link>
                    <div className="custom-check">
                      <Checkbox onChange={e => setRememberMe(e.target.checked)}>{t("Remember Me")}</Checkbox>
                    </div>
                  </form>
                  <p className="error my-4">{error}</p>
                  <div className="login-btns">
                    <Button
                      className="login-btn"
                      onClick={login}
                      disabled={loading || !userID || !password}
                    >
                      {loading ?
                        <div className="spinner-border" />
                        :
                        <>
                          {t("Sign In")} <img src={BtnArrow} alt="arrow" />
                        </>
                      }
                    </Button>
                    <h4>{t("OR")}</h4>
                    <FacebookLogin
                      appId="2363255820519921"
                      fields="name,email,picture"
                      scope="public_profile,email"
                      onSuccess={(response) => {
                        console.log('Login Success!', response);
                      }}
                      onFail={(error) => {
                        console.log('Login Failed!', error);
                      }}
                      onProfileSuccess={(res) => {
                        console.log('Get Profile Success!', res);
                        let payload = {
                          email: res.email,
                          login_plateform: 'Facebook',
                          login_plateform_id: res.id,
                          password: res.id,
                        }
                        callLoginAPI(payload);
                      }}
                      render={({ onClick }) => (
                        <div
                          className="social-login"
                          onClick={onClick}
                        >
                          <img src={Facebook} alt="facebook" />
                        </div>
                      )}
                    />
                    <div
                      className="social-login"
                      onClick={() => {
                        googleLogin();
                      }}
                    >
                      <img src={Google} alt="google" />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid ml-auto d-block"
                src={Banner}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
