import React, { useState } from "react";
import { Button } from "antd";
import Arrow from "../../assets/images/btn-arrow.png";
import "../../assets/scss/_wallet.scss";
import MetamaskModal from "../MetamaskModal/MetamaskModal";
import Wallet from "../../assets/images/white-w.png";

const ConnectWallet = (props) => {
  const [address, setAddress] = useState("");
  const [showMetamaskModal, setShowMetamaskModal] = useState(false);
  const { alternateDesign } = props;

  const Handlewallet = () => {
    let provider = window.ethereum;
    if (typeof provider !== "undefined") {
      if (address) {
        alert("already_connected");
        return;
      }
      provider
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          setAddress(window.ethereum.selectedAddress);
          console.log(`Selected account is ${address}`);
          alert("account_connected");
        })
        .catch((err) => {
          console.log(err);
          alert("not_connect");
          return;
        });

      window.ethereum.on("accountsChanged", function (accounts) {
        setAddress(window.ethereum.selectedAddress);
        console.log(`Selected account changed to ${address}`);
      });
    } else {
      // if (window.navigator.vendor == "Apple Computer, Inc.")
      //   alert(t("not_support"));
      // else alert(t("install_metamask"));
      //   setShow(true);
      setShowMetamaskModal(true);
    }
  };
  return (
    <>
      {alternateDesign ?
        <Button className="modal-btn" onClick={Handlewallet}>
          {address ?
            "Wallet Connected"
            :
            <>
              <img src={Wallet} alt="wallet" />
              Connect Wallet
            </>
          }
        </Button>
        :
        <div>
          <div className="buy-token-btn">
            <Button onClick={Handlewallet} className="wallet-btn">
              {address ?
                "Wallet Connected"
                :
                <>
                  Connect Wallet <img src={Arrow} alt="arrow" />
                </>
              }
            </Button>
            {/* <Button className="wallet-btn">
    Add Your Wallet Address Before Buy <img src={Arrow} alt="arrow" />
  </Button> */}
          </div>
        </div>
      }
      <MetamaskModal show={showMetamaskModal} setShow={setShowMetamaskModal} />
    </>
  );
};

export default ConnectWallet;
