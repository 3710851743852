import React, { useState, useEffect } from "react";
import { Button } from "antd";
import axios from "axios";
import { baseURL, formatAmount } from "../../constants";
import Arrow from "../../assets/images/btn-arrow.png";
import "../../assets/scss/_wallet.scss";
import I from "../../assets/images/ig.png";

const TransactionModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [transactionData, setTransactionData] = useState({});

  const token = localStorage.getItem("token");
  const { transactionID } = props;

  useEffect(() => {
    if (transactionID) {
      setError(false);
      setLoading(true);
      axios
        .get(`${baseURL}/view-transaction/${transactionID}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          console.log('Transactions', res.data);
          setTransactionData(res.data?.data);
        })
        .catch(err => {
          setError(true);
          console.log(err.response?.data);
        })
        .finally(() => setLoading(false));
    }
  }, [transactionID]);

  return (
    <div
      className="modal fade"
      id="transaction"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div className="modal-body">
            <div className="payment-modal-cont">
              <h4>Transaction Details</h4>
              {loading ?
                <div className="text-center py-5">
                  <div className="spinner-border" />
                </div>
                :
                error ?
                  <div className="text-center py-5">
                    <p>Unable to load transaction data. An error occurred.</p>
                  </div>
                  :
                  <>
                    <div className="transaction-header">
                      <div>
                        <h5>Tranx Date</h5>
                        <p>{new Date(transactionData.created_at).toLocaleString()}</p>
                      </div>
                      <div>
                        <h5>Tranx Status</h5>
                        <p>Status</p>
                      </div>
                      <div>
                        <h5>Tranx Approved Note</h5>
                        <p>By Admin at {new Date(transactionData.updated_at).toLocaleString()}</p>
                      </div>
                    </div>
                    <div className="transaction-info">
                      <h4>TRANSACTION INFO</h4>
                      <div>
                        <h5>Transaction Type</h5>
                        <p>{transactionData.type}</p>
                      </div>
                      <div>
                        <h5>Payment Getway</h5>
                        <p>Ethereum - Offline Payment</p>
                      </div>
                      <div>
                        <h5>Deposit From</h5>
                        <p>0xa87d264f935920005810653734156d3342d5c385</p>
                      </div>
                      <div>
                        <h5>Tx Hash</h5>
                        <p>
                          {transactionData.transaction_no}
                        </p>
                      </div>
                      <div>
                        <h5>Deposit To</h5>
                        <p>{transactionData.to}</p>
                      </div>
                      <div>
                        <h5>Details</h5>
                        <p>Tokens Purchase</p>
                      </div>
                    </div>
                    <div className="token-details">
                      <h4>TOKEN DETAILS</h4>
                      <div>
                        <div>
                          <h5>Stage Name</h5>
                          <p>ICO Phase 3</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Contribution</h5>
                          <p>
                            {formatAmount(transactionData.amount)} ETH <img src={I} alt="hint" />
                          </p>
                        </div>
                        <div>
                          <p>
                            <img src={I} alt="hint" />
                            {formatAmount(transactionData.eur_amount)} EUR
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Tokens Added To</h5>
                          <p>
                            UD1020001 <small>- infoicox@gmail..com</small>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Token (T)</h5>
                          <p>4,780.00</p>
                        </div>
                        <div>
                          <p>(4780 * 1)</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Bonus Tokens (B)</h5>
                          <p>956.00</p>
                        </div>
                        <div>
                          <p>(956 * 1)</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Total Tokens</h5>
                          <p>5,736.00</p>
                        </div>
                        <div>
                          <p>(T+B)</p>
                        </div>
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TransactionModal;
