import React from "react";

import "../../assets/scss/_privacy.scss";

const Privacy = (props) => {
  const { language } = props;

  return (
    <div className="privacy">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="privacy-cont">
              <h3>Privacy and Policy</h3>
              <p>PAYBIUS Privacy and Policies.</p>
              {language === "gm" ?
                <>
                  <p className="first-para">
                    Datenschutzerklärung - Paybius - GmbH
                  </p>
                  <h4>1. Grundlegendes</h4>
                  <p>
                    Der SchutzIhrer Privatsphäre ist uns ein wichtiges Anliegen.
                    Deshalb möchten wir Siebitten, die nachfolgende Zusammenfassung
                    über die Datenerhebung aufmerksam zulesen.
                  </p>
                  <p>
                    Die hieraufgeführte Datenschutzerklärung soll über die Art, den
                    Umfang und den Zweckder Erhebung personenbezogener Daten durch
                    den Websitebetreiber PaybiusGmbH aufklären. Die Datenerhebung
                    und -weitergabe entspricht den Anforderungender DSGVO und des
                    BDSG. Änderungen und entsprechende Aktualisierungen
                    derDatenschutzerklärung sind nicht auszuschließen, weshalb wir
                    Ihnen empfehlen,diese Datenschutzerklärung regelmäßig zur
                    Kenntnis zu nehmen.
                  </p>
                  <p>
                    Wirversichern, dass diese Daten ausschließlich dem
                    Funktionieren, der statistischenAuswertung und der Verbesserung
                    unseres Angebotes dienen. Laut geltendenVorschriften werden
                    diese Daten nur für die Zeit gespeichert, wie es für
                    diebeschriebenen Zwecke notwendig ist. Ausnahmen gelten, wenn
                    eine längereSpeicherung durch die DSGVO vorgesehen ist. Die
                    gesetzlich geltendenLöschvorschriften werden eingehalten.
                  </p>
                  <p>
                    Wir nehmenden Schutz Ihrer Daten ernst und behandeln diese
                    vertraulich. Sollten Siedennoch Anliegen oder Fragen bzgl. der
                    Erhebung Ihrer Daten haben, finden Sieam Ende des Textes die
                    entsprechenden Kontaktdaten zuständiger Ansprechpartner.
                  </p>
                  <h4>2. Personenbezogene Daten</h4>
                  <p>
                    Hier giltes, zwischen personenbezogenen Daten nach Art.4 DSGVO
                    und besonderenpersonenbezogenen Daten nach Art. 9 DSGVO zu
                    unterscheiden. Zudem beantwortenwir, welche personenbezogene
                    Daten wir wie und warum verarbeiten. 2.1Personenbezogene Daten
                  </p>
                  <p>
                    DieseWebsite erhebt und nutzt personenbezogene Daten. Die hier
                    vorgenommeneDatenerhebung entspricht einem berechtigten
                    Interesse laut Art.6 Abs. 1Buchstabe f der DSGVO. Zudem sind wir
                    für die Bereitstellung unseres Serviceszu Teilen auf
                    Informationen unserer Nutzer angewiesen. Daten werdennur in dem
                    Umfang erhoben, verwertet und an Dritte weitergeleitet, wie dies
                    imgesetzlichen Rahmen erlaubt ist und/oder wenn Nutzer dem
                    zugestimmt haben. Wirals Betreiber dürfen personenbezogene Daten
                    nur dann länger speichern,herausgeben oder nachträglich auf
                    diese zugreifen, soweit dies im rechtlichgestattet ist (z.B. bei
                    Verdacht auf rechtswidrige Aktivitäten).
                  </p>
                  <p>
                    Durch dasBesuchen und Benutzen unserer Seite können verschiedene
                    personenbezogene Datennach Art.4 DSGVO erhoben werden, welche
                    Sie im Folgenden aufgelistet finden.
                  </p>
                  <h4>2.1.1 Zugriffsdaten</h4>
                  <p>
                    Im Zugeeines automatischen Protokolls des verarbeitenden
                    Computersystems wird eineLogdatei erstellt. Das bedeutet, dass
                    Informationen Ihres verwendetenEndgerätes als Logfiles auf einem
                    Server festgehalten werden. Durch den Zugriffauf unsere Seite
                    erheben wir solche Daten, welche als Server-Logfiles
                    abgelegtwerden. Dies erfolgt aus Sicherheitsgründen.
                    Server-Logfiles werden für eineZeit von höchstens sieben Tagen
                    gespeichert und anschließend gelöscht. Die soerhobenen,
                    personenbezogenen Daten umfassen:
                  </p>
                  <ul className="list-unstyled">
                    <li>- Zugriffauf die Seite: Datum, Uhrzeit, Häufigkeit</li>
                    <li>
                      - wieSie auf die Seite gelangt sind (vorherige Seite,
                      Hyperlink etc.)
                    </li>
                    <li>- Mengeder gesendeten Daten</li>
                    <li>
                      - welchenBrowser und welche Version desselben Sie verwenden
                    </li>
                    <li>- IhreIP-Adresse</li>
                  </ul>
                  <p>
                    Sie könnenunser Angebot auch nutzen, ohne Informationen Ihrer
                    Person preiszugeben. ImRegelfall werden wir dennoch Ihre
                    anonymisierten Zugriffsdaten speichern, ohneRückschluss auf die
                    natürliche Person.
                  </p>
                  <h4>2.1.2 Kontaktdatenüber Formulare und Kommentarfunktion</h4>
                  <p>
                    Wenn Sie mituns Kontakt aufnehmen (über das Kontaktformular, die
                    Kommentarfunktion, E-Mailetc.) dann werden im Regelfall Ihr
                    Name, Ihre E-Mail-Adresse und IhreIP-Adresse erhoben. Auch
                    hierfür besteht ein berechtigtes Interesseunsererseits, wenn z.
                    B. Ihr Kommentar gegen geltendes Recht verstößt. Zudembehalten
                    wir es uns grundsätzlich vor, Äußerungen mit
                    menschenverachtenden,diffamierenden, homophoben und ähnlichen
                    hetzerischen Inhalten zu löschen und,wenn notwendig, zur Anzeige
                    zu bringen.
                  </p>
                  <h4>
                    2.1.3 Daten,die durch unseren Verkaufsservice erhoben werden
                  </h4>
                  <p>
                    Wenn Sie denVerkaufsservice unserer Seite benutzen, dann sind
                    die so erhobenenInformationen nicht für andere Nutzer einsehbar.
                    Zu diesen Daten zählen:
                  </p>
                  <ul className="list-unstyled">
                    <li>- Vor-und Zuname</li>
                    <li>- Anschrift</li>
                    <li>- Geburtsdatum</li>
                    <li>- E-Mail-Adresse</li>
                    <li>- Kreditinstitute</li>
                    <li>- Ihreaufgegebene Bestellung</li>
                    <li>- frühereBestellungen</li>
                    <li>- kryptographischeAdresse Ihrer Wallet</li>
                  </ul>
                  <h4>2.2 Besonders personenbezogene Daten</h4>
                  <p>
                    Es werdenkeine besonderen personenbezogenen Daten nach Art. 9
                    DSGVO erhoben.
                  </p>
                  <h4>3. Datenweitergabe an Dritte</h4>
                  <p>
                    Geben SieDaten an dritte Stellen weiter, dann sind diese
                    ausnahmslos namentlich und mitAnschrift des Sitzes zu benennen.
                    Grundsätzlichwerden Ihre Daten von uns nicht an Dritte
                    vermittelt. Außerdem stellen wirdurch entsprechende Maßnahmen
                    und regelmäßige Kontrollen sicher, dass die vonuns erhobenen
                    Daten nicht durch Dritte von außen eingesehen oder
                    abgegriffenwerden können.
                  </p>
                  <h4>4. Cookies</h4>
                  <p>
                    DieseWebsite setzt Cookies ein. Dies sind kleine Textdateien,
                    welche in IhremBrowser platziert werden. Auf die Art werden Ihr
                    Browser und Ihr Gerät beieiner nächsten Anmeldung wiedererkannt.
                    Dadurch können bei Ihrem nächstenBesuch bereits getätigte
                    Einstellungen und andere Änderungen, welche Sievorgenommen
                    haben, rekonstruiert werden. Wir verwenden Cookies, um
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      - unserenDienst zu personalisieren und somit
                      benutzerfreundlicher zu gestalten
                    </li>
                    <li>
                      - Betrugsversuchezu verhindern und Sicherheit zu gewährleisten
                    </li>
                    <li>- interessenbasierteWerbung zu schalten</li>
                    <li>- pseudonymisierteReichweitenmessung durchzuführen</li>
                  </ul>
                  <p>
                    Sie habenjederzeit die Möglichkeit, das Setzen von Cookies zu
                    blockieren und bereitsgesetzte Cookies in Ihrem verwendeten
                    Browser zu löschen. In solch einem Fallmüssen wir Sie darauf
                    hinweisen, dass bestimmte Features auf der Seiteeventuell nicht
                    mehr oder nur noch eingeschränkt funktionieren.
                  </p>
                  <p>
                    Wünschen Sienicht, dass Cookies zur Reichweitenmessung eines
                    bestimmten Anbieterseingesetzt werden, dann können Sie über
                    folgende Seiten entsprechendeEinstellungen tätigen:
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      - Cookie-Deaktivierungsseiteder Netzwerkwerbeinitiative:
                      http://optout.networkadvertising.org/?c=1#!/
                    </li>
                    <li>
                      - Cookie-Deaktivierungsseiteder US-amerikanischen Website:
                      http://optout.aboutads.info/?c=2#!/
                    </li>
                    <li>
                      - Cookie-Deaktivierungsseiteder europäischen Website:
                      http://optout.networkadvertising.org/?c=1#!/
                    </li>
                  </ul>
                  <h4>5. Webanalysediente</h4>
                  <p>
                    Diese Seiteverwendet Google Analytics. Dabei handelt es sich um
                    einen Webanalysedienst derGoogle Inc. (1600 Amphitheatre Parkway
                    Mountain View, CA 94043, USA) bzw.Google Ireland Limited (Google
                    Ireland Limited, Gordon House, Barrow Street,Dublin 4, Ireland),
                    durch welchen wir unser Angebot verbessern. Laut geltendemRecht
                    sind wir dazu verpflichtet, Sie genau über die Datenerhebung
                    durch GoogleAnalytics aufzuklären.
                  </p>
                  <p>
                    DieserDienst ermöglicht es uns, durch Erstellen von Reports die
                    Nutzung unsererWebseite nachzuvollziehen und somit unser Angebot
                    auf die Nachfragen derBenutzer anzupassen.
                  </p>
                  <p>
                    Auf dieserWebsite wurde eine IP-Anonymisierung für den Dienst
                    eingerichtet. Das bedeutet,dass IP-Adressen in den
                    Vertragsstaaten gekürzt werden. Nur in Ausnahmefällenwerden
                    IP-Adressen zunächst in die USA an einen entsprechenden
                    Serverübertragen und dort gekürzt. Google Analyticssetzt eigene
                    Cookies und ermittelt so Daten unserer Kunden. Möchten Sie
                    diebetreffenden Cookies deaktivieren, finden Sie unter folgendem
                    Link einentsprechendes Browser-Plugin:{""}
                    {/* eslint-disable-next-line */}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout?hl=de"
                      target="_blank"
                    >
                      https://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    .
                  </p>
                  <p>
                    Daten,welche von Google Analytics generiert werden, umfassen:
                  </p>
                  <ul className="list-unstyled">
                    <li>- Artdes Browsers plus dessen Version</li>
                    <li>- dasBetriebssystem, welches Sie verwenden</li>
                    <li>- wielange Sie auf unserer Seite bleiben</li>
                    <li>- diezuvor besuchte Webseite</li>
                  </ul>
                  <p>
                    Die soerhobenen Daten werden nicht mit anderen, durch
                    Google-Dienste erhobene Datenzusammengeführt.
                  </p>
                  <h4>6. Social-Media-Plugins</h4>
                  <p>
                    Auf unsererWebseite bieten wir die Möglichkeit, über externe
                    Links Inhalte in sozialenNetzwerken oder per Mail zu teilen.
                    Dies realisieren wir über Social Bookmarks.Das sind
                    Schaltflächen, welche an dem Logo des jeweiligen Sozialen
                    Netzwerkeszu erkennen sind. Das Plugin stellt eine Verbindung
                    zwischen Ihrem Browser undden Servern des jeweiligen Anbieters
                    her. Benutzen Sie diese Social Bookmarks,werden Sie im Regelfall
                    auf die Seiten der Anbieter weitergeleitet undentsprechende
                    Nutzerinformationen weitergegeben.
                  </p>
                  <p>
                    Wenn Siewährend der Benutzung unserer Seite auf einem dieser
                    sozialen Netzwerke mitIhrem Konto angemeldet sind und unsere
                    Inhalte teilen möchten, dann werdendiese Informationen mit Ihrem
                    jeweiligen Konto verknüpft. Weiterhin besteht dieMöglichkeit,
                    dass Ihre IP-Adresse ausgelesen wird. Wir als Betreiber
                    habenkeinen Einfluss auf die Daten, welche durch Plug-Ins an
                    entsprechende Serververmittelt werden. WeitereInformationen zum
                    Umgang mit Ihren Daten entnehmen Sie bitte
                    derDatenschutzerklärung des jeweiligen Anbieters:
                  </p>
                  <p>
                    Facebook: {/* eslint-disable-next-line */}
                    <a href="https://de-de.facebook.com/policy.php" target="_blank">
                      {" "}
                      https://de-de.facebook.com/policy.php
                    </a>
                  </p>
                  <p>
                    Twitter: {/* eslint-disable-next-line */}
                    <a href="https://twitter.com/privacy?lang=de" target="_blank">
                      https://twitter.com/privacy?lang=de
                    </a>
                  </p>
                  <p>
                    Instagram:
                    {/* eslint-disable-next-line */}
                    <a
                      href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect&__coig_consent=1"
                      target="_blank"
                    >
                      https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect&__coig_consent=1
                    </a>
                  </p>
                  <p>
                    YouTube: {/* eslint-disable-next-line */}
                    <a href="https://policies.google.com/privacy" target="_blank">
                      {" "}
                      https://policies.google.com/privacy
                    </a>
                  </p>
                  <h4>7. Ihre Rechte</h4>
                  <p>
                    Selbstverständlichhaben Sie in Bezug auf die Erhebung Ihrer
                    Daten Rechte. Laut geltendem Gesetzsind wir dazu verpflichtet,
                    Sie über dieselben aufzuklären. Die Inanspruchnahmeund
                    Durchführung dieser Rechte sind für Sie kostenlos.
                    Widerrufsrecht(Art. 21 DSGVO)
                  </p>
                  <p>
                    Sie habendas Recht, Ihre Einwilligung bzgl. der Erhebung von
                    Daten jederzeit zuwiderrufen.
                  </p>
                  <p>
                    Folgen Siediesem Link, wenn Sie von Ihrem Widerrufsrecht
                    Gebrauch machen möchten:
                    {/* eslint-disable-next-line */}
                    <a target="_blank" href="www.musterseite.de/widerruf">
                      www.musterseite.de/widerruf
                    </a>{" "}
                    Recht aufDatenübertragbarkeit
                  </p>
                  <p>
                    Sie habendas Recht, eine Übertragung Ihrer Daten von uns auf
                    eine andere Stelle zubeantragen. Auskunftsrecht (Art. 15 DSGVO)
                  </p>
                  <p>
                    Sie habendas Recht, von uns eine Bestätigung zu verlangen, ob
                    und wie wirpersonenbezogene Daten von Ihnen beziehen. Dieses
                    Recht ist durch dievorliegende Datenschutzerklärung realisiert.
                    Zusätzlich können Sie eineelektronische Auskunft anfordern.
                  </p>
                  <p>
                    Recht aufBerechtigung, Löschung oder Sperrung (Art. 16, 17, 18
                    DSGVO)
                  </p>
                  <p>
                    Sie habendas Recht, Ihre Daten berichtigen, löschen oder sperren
                    zu lassen. Letztereskommt zur Anwendung, wenn die gesetzliche
                    Lage eine Löschung nicht zulässt.
                  </p>
                  <p>Beschwerderecht(Art. 77 DSGVO)</p>
                  <p>
                    Sie habendas Recht, sich bei einer Aufsichtsbehörde bzw. einer
                    zuständigen Stelle zubeschweren, insofern Sie einen Grund zur
                    Beanstandung haben sollten. Für dieInanspruchnahme dieses Rechts
                    und der zwei vorher genannten wenden Sie sichbitte an die am
                    Ende dieser Datenschutzerklärung aufgeführten Kontaktpersonen.
                  </p>
                  <h4>8. Verantwortlich für die Datenerhebung</h4>
                  <p>Stand: 21.Oktober 2022</p>
                </>
                :
                <>
                  <p className="first-para">
                    Privacy policy - Paybius - GmbH
                  </p>
                  <h4>1. Basic Information</h4>
                  <p>
                    Protecting your privacy is important to us. Therefore, we would like to ask you to carefully read the following summary about data collection.
                  </p>
                  <p>
                    The privacy policy set out here is intended to clarify the nature, scope and purpose of the collection of personal data by the website operator PaybiusGmbH. The collection and transfer of data complies with the requirements of the DSGVO and the BDSG. Changes and corresponding updates to the data protection declaration cannot be ruled out, which is why we recommend that you regularly take note of this data protection declaration.
                  </p>
                  <p>
                    We assure that this data is used exclusively for the functioning, statistical evaluation and improvement of our offer. According to current regulations, this data is only stored for the time necessary for the purposes described. Exceptions apply if longer storage is provided for by the GDPR. The legally applicable deletion regulations are complied with.
                  </p>
                  <p>
                    We take the protection of your data seriously and treat it confidentially. If you still have concerns or questions regarding the collection of your data, you will find at the end of the text the appropriate contact details of responsible contact persons.
                  </p>
                  <h4>2. Personal Data</h4>
                  <p>
                    Here we have to distinguish between personal data according to Art. 4 DSGVO and special personal data according to Art. 9 DSGVO. In addition, we answer which personal data we process, how and why. 2.1Personal data
                  </p>
                  <p>
                    This website collects and uses personal data. The data collection carried out here corresponds to a legitimate interest according to Art.6 para. 1 letter f of the DSGVO. In addition, we are partly dependent on information from our users for the provision of our service. Data will only be collected, used and forwarded to third parties to the extent permitted by law and/or if users have consented to this. We, as the operator, may only store personal data for a longer period of time, release it or subsequently access it to the extent that this is legally permitted (e.g. in the case of suspicion of illegal activities).
                  </p>
                  <p>
                    By visiting and using our site, various personal data can be collected according to Art.4 DSGVO, which you will find listed below.
                  </p>
                  <h4>2.1.1 Access data</h4>
                  <p>
                    In the course of an automatic protocol of the processing computer system, a log file is created. This means that information from the terminal device you are using is stored as log files on a server. When you access our site, we collect such data, which is stored as server log files. This is done for security reasons. Server log files are stored for a maximum of seven days and then deleted. The personal data collected in this way includes:
                  </p>
                  <ul className="list-unstyled">
                    <li>- access to the page: date, time, frequency</li>
                    <li>
                      - how you arrived at the page (previous page, hyperlink, etc.)
                    </li>
                    <li>- amount of data sent</li>
                    <li>
                      - which browser and which version of the same you are using
                    </li>
                    <li>- your IP address</li>
                  </ul>
                  <p>
                    You can also use our offer without disclosing any information about yourself. As a rule, we will nevertheless store your anonymized access data, without drawing any conclusions about the natural person.
                  </p>
                  <h4>2.1.2 Contact data via forms and comment function</h4>
                  <p>
                    If you contact us (via the contact form, the comment function, e-mail, etc.), your name, e-mail address and IP address are usually collected. There is also a legitimate interest on our part, for example, if your comment violates applicable law. In addition, we reserve the right to delete comments with inhuman, defamatory, homophobic and similar inflammatory content and, if necessary, to report them to the police.
                  </p>
                  <h4>
                    2.1.3 Data collected through our sales service
                  </h4>
                  <p>
                    When you use the sales service of our site, the information collected in this way is not visible to other users. This data includes:
                  </p>
                  <ul className="list-unstyled">
                    <li>- First and last name</li>
                    <li>- Your addresst</li>
                    <li>- Date of birth</li>
                    <li>- E-mail address</li>
                    <li>- Credit institutions</li>
                    <li>- Your order</li>
                    <li>- Previous orders</li>
                    <li>- Cryptographic address of your wallet</li>
                  </ul>
                  <h4>2.2 Special personal data</h4>
                  <p>
                    No special personal data according to Art. 9 DSGVO will be collected.
                  </p>
                  <h4>3. Data transfer to third parties</h4>
                  <p>
                    If you pass on data to third parties, then these are to be named without exception and with the address of the registered office. As a matter of principle, we will not pass on your data to third parties. Furthermore, we take appropriate measures and carry out regular checks to ensure that the data we collect cannot be viewed or accessed by third parties from outside.
                  </p>
                  <h4>4. Cookies</h4>
                  <p>
                    This website uses cookies. These are small text files that are placed in your browser. In this way, your browser and your device will be recognized the next time you log in. This means that the settings you have already made and other changes you have made can be reconstructed on your next visit. We use cookies to
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      - personalize our service and make it more user-friendly
                    </li>
                    <li>
                      - prevent fraud attempts and to ensure security
                    </li>
                    <li>- to provide interest-based advertising</li>
                    <li>- to carry out pseudonymous range measurement</li>
                  </ul>
                  <p>
                    You have the option at any time to block the setting of cookies and to delete cookies already set in your browser. In such a case, we must inform you that certain features on the site may no longer function or only function to a limited extent.
                  </p>
                  <p>
                    If you do not wish cookies to be used to measure the reach of a particular provider, you can make the appropriate settings on the following pages:
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      - Cookie deactivation page of the network advertising initiative:
                      http://optout.networkadvertising.org/?c=1#!/
                    </li>
                    <li>
                      - Cookie deactivation page of the US website:
                      http://optout.aboutads.info/?c=2#!/
                    </li>
                    <li>
                      - Cookie deactivation page of the European website:
                      http://optout.networkadvertising.org/?c=1#!/
                    </li>
                  </ul>
                  <h4>5. Web analytics service</h4>
                  <p>
                    This site uses Google Analytics. This is a web analysis service of Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) or Google Ireland Limited (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland), through which we improve our offer. According to applicable law, we are obliged to inform you in detail about the data collection by Google Analytics.
                  </p>
                  <p>
                    This service enables us to track the use of our website by creating reports and thus to adapt our offer to the demands of the users.
                  </p>
                  <p>
                    On this website, IP anonymization has been set up for the service. This means that IP addresses are shortened in the contracting states. Only in exceptional cases will IP addresses first be transferred to a corresponding server in the USA and shortened there. Google Analytic sets its own cookies and thus collects data from our customers. If you would like to deactivate the relevant cookies, you can find a corresponding browser plug-in under the following link:
                    {/* eslint-disable-next-line */}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout?hl=de"
                      target="_blank"
                    >
                      https://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    .
                  </p>
                  <p>
                    Data generated by Google Analytics include:
                  </p>
                  <ul className="list-unstyled">
                    <li>- Type of browser plus its versionn</li>
                    <li>- The operating system you are using</li>
                    <li>- How long you stay on our site</li>
                    <li>- The web page you visited before</li>
                  </ul>
                  <p>
                    The data collected in this way will not be merged with other data collected by Google services.
                  </p>
                  <h4>6. Social media plugins</h4>
                  <p>
                    On our website, we offer the possibility of sharing content via external links in social networks or by e-mail. We realize this via social bookmarks.These are buttons, which are recognizable by the logo of the respective social networkzu. The plugin establishes a connection between your browser and the servers of the respective provider. If you use these social bookmarks, you will usually be redirected to the pages of the provider and corresponding user information will be passed on.
                  </p>
                  <p>
                    If you are logged into one of these social networks with your account while using our site and want to share our content, this information will be linked to your respective account. Furthermore, it is possible that your IP address will be read. As the operator, we have no influence on the data that is transmitted to the corresponding server by plug-ins. For further information on the handling of your data, please refer to the data protection declaration of the respective provider:
                  </p>
                  <p>
                    Facebook: {/* eslint-disable-next-line */}
                    <a href="https://de-de.facebook.com/policy.php" target="_blank">
                      {" "}
                      https://de-de.facebook.com/policy.php
                    </a>
                  </p>
                  <p>
                    Twitter: {/* eslint-disable-next-line */}
                    <a href="https://twitter.com/privacy?lang=de" target="_blank">
                      https://twitter.com/privacy?lang=de
                    </a>
                  </p>
                  <p>
                    Instagram:
                    {/* eslint-disable-next-line */}
                    <a
                      href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect&__coig_consent=1"
                      target="_blank"
                    >
                      https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect&__coig_consent=1
                    </a>
                  </p>
                  <p>
                    YouTube: {/* eslint-disable-next-line */}
                    <a href="https://policies.google.com/privacy" target="_blank">
                      {" "}
                      https://policies.google.com/privacy
                    </a>
                  </p>
                  <h4>7. Your Rights</h4>
                  <p>
                  Of course, you have rights in relation to the collection of your data. According to the applicable law, we are obliged to inform you about the same. The use and implementation of these rights are free of charge for you. Right of withdrawal(Art. 21 DSGVO)
                  </p>
                  <p>
                  You have the right to withdraw your consent to the collection of data at any time.
                  </p>
                  <p>
                  Follow this link if you wish to exercise your right of withdrawal:
                    {/* eslint-disable-next-line */}
                    <a target="_blank" href="www.musterseite.de/widerruf">
                      www.musterseite.de/widerruf
                    </a>{" "}
                    Right to data portability
                  </p>
                  <p>
                  You have the right to request a transfer of your data from us to another entity. Right of access (Art. 15 DSGVO)
                  </p>
                  <p>
                  You have the right to request confirmation from us as to whether and how we obtain personal data from you. This right is realized by thepresent privacy policy. In addition, you can request electronic information.
                  </p>
                  <p>
                  Right of authorization, deletion or blocking (Art. 16, 17, 18 DSGVO)
                  </p>
                  <p>
                  You have the right to have your data corrected, deleted or blocked. The latter applies if the legal situation does not permit deletion.
                  </p>
                  <p>Right of appeal(Art. 77 DSGVO)</p>
                  <p>
                  You have the right to complain to a supervisory authority or a competent body, insofar as you have a reason to complain. To exercise this right and the two previously mentioned, please contact the contact persons listed at the end of this Privacy Policy.
                  </p>
                  <h4>8. Responsible for data collection</h4>
                  <p>Status: October 21, 2022</p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
