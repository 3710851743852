import React, { useEffect, useState } from "react";
// assets
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useTranslation } from "react-i18next";
import "../../assets/scss/_header.scss";
// import User from "../../assets/images/user-img.png";
import Logo from "../../assets/images/logo-ico-new.png";

import Dashboard from "../../assets/images/navbar-icons/dash.png";
import Buy from "../../assets/images/navbar-icons/buy.png";
import Transactions from "../../assets/images/navbar-icons/trans.png";
import Withdraw from "../../assets/images/navbar-icons/withdraw.png";
import Profile from "../../assets/images/navbar-icons/profile.png";
import Token from "../../assets/images/navbar-icons/token.png";
import Site from "../../assets/images/navbar-icons/site.png";
import KYC from "../../assets/images/navbar-icons/kyc.png";

import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [userName, setUserName] = useState("");
  const { t } = useTranslation();

  const location = useLocation();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("expiryTime");
    window.location.reload();
  };

  const items = [
    {
      label: <span onClick={logOut}>{t("Logout")}</span>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: <a href="/#">{t("Disconnect Wallet")}</a>,
      key: "0",
    },
  ];

  // Hide mobile menu when user navigates to a different pagae
  const navLinks = document.querySelectorAll(
    ".headList li, .navbar-brand, .user-links > div "
  );
  const menuToggle = document.getElementById("navbarSupportedContent");
  navLinks.forEach((l) => {
    l.addEventListener("click", () => {
      menuToggle.classList.remove("show");
    });
  });

  useEffect(() => {
    if (user) {
      let headerUserInfo = document.querySelector("#header-user-info");
      headerUserInfo.addEventListener("profileUpdated", (event) => {
        event.stopImmediatePropagation();
        event.preventDefault();
        setUserName(event.detail.updatedName);
      });
      setUserName(user.name);
    }
  }, [user]);

  return (
    <div className="header-main">
      <div className="top-bar">
        <div className="container">
          <div className="top-bar-cont">
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="Logo" />
              Paybius
            </Link>
            {user ? (
              <div id="header-user-info" className="login-btns">
                <img className="user-avatar" src={user.image} alt="user" />
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <Link onClick={(e) => e.preventDefault()}>
                    <Space>
                      {t("Welcome")}! {userName}
                      <DownOutlined />
                    </Space>
                  </Link>
                </Dropdown>
              </div>
            ) : (
              <div className="login-btns login-links">
                <Link to="/login">{t("Login")}</Link>
                <span> | </span>
                <Link to="/sign-up">{t("Sign Up")}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*eslint-disable */}
      <div className="header-nav">
        <div className="container">
          <nav className="navbar navbar-expand-lg  bg-light">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fa fa-bars"></i>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav headList">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={
                      location.pathname == "/" ? "nav-link active" : "nav-link"
                    }
                  >
                    <img src={Dashboard} alt="dashboard" />
                    {t("Dashboard")}
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to="/buy-token"
                    className={
                      location.pathname == "/buy-token"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={Buy} alt="buy" />
                    {t("Buy Tokens")}
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to="/transactions"
                    className={
                      location.pathname == "/transactions"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={Transactions} alt="transactions" />
                    {t("Transactions")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/withdraw"
                    className={
                      location.pathname == "/withdraw"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={Withdraw} alt="withdraw" />
                    {t("Withdraw")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/profile"
                    className={
                      location.pathname == "/profile"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={Profile} alt="profile" />
                    {t("Profile")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/my-token"
                    className={
                      location.pathname == "/my-token"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={Token} alt="token" />
                    {t("My Token")}
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/#" className="nav-link ">
                    <img src={Site} alt="site" />
                    {t("Main Site")}
                  </a>
                </li>
              </ul>
              <Button className="ml-auto kyc-btn">
                <img src={KYC} alt="kyc" />
                {t("KYC Applications")}
              </Button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
