export const baseURL = "https://icoend.gap-dynamics.com/api";

export const emailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

export const formatAmount = (value, dp = 2) => {
    if (value > 0) {
        let outputStr = parseFloat(value).toFixed(dp);
        return outputStr;
    } else {
        return '0.00';
    }
}