import React, { useState, useEffect } from "react";
import "../../assets/scss/_signup.scss";
import { Button } from "antd";
import axios from "axios";
import { baseURL, emailRegex } from "../../constants";
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useTranslation } from "react-i18next";

import Banner from "../../assets/images/login-img.png";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../../assets/images/arrow2.png";
import Google from "../../assets/images/google.png";
import Facebook from "../../assets/images/facebook.png";
import Show from "../../assets/images/show.svg";
import Hide from "../../assets/images/hide.svg";
import BtnArrow from "../../assets/images/btn-arrow.png";
import Icon from "../../assets/images/list-icon.png";
import Okay from "../../assets/images/ok.png";

const SignUp = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState([false, false, false]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorObj, setErrorObj] = useState({});
  const [success, setSuccess] = useState(false);

  const {t} = useTranslation();

  const navigate = useNavigate();
  const passwordSymbolRegex = new RegExp(/[0-9]/);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmpasswordShown);
  };

  const updatePasswordAndValidate = e => {
    let inputVal = e.target.value;
    setPassword(inputVal);
    let validationChecks = [false, false, false];
    validationChecks[0] = (inputVal.length >= 8);
    validationChecks[1] = passwordSymbolRegex.test(inputVal);
    validationChecks[2] = inputVal.toLowerCase() !== inputVal
    setPasswordValidation(validationChecks);
    if (validationChecks.indexOf(false) >= 0)
      setError("Password does not meet the required criteria");
    else
      setError("");
  };

  const signUp = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
    let payload = {
      name: userName,
      email: userID,
      password
    }
    callSignUpAPI(payload);
  }

  const callSignUpAPI = (payload, socialLogin) => {
    setError("");
    setErrorObj({});

    if (!emailRegex.test(payload.email)) {
      setError("Please enter a valid email address.");
      return;
    }
    
    setLoading(true);
    console.log('Payload', payload);

    axios
      .post(`${baseURL}/signup`, payload)
      .then(res => {
        console.log('Sign Up Response', res.data);
        if (res.data.status === 200) {
          if (socialLogin) {
            setSuccess("autoLogin");
            localStorage.setItem('token', res.data.access_token);
            localStorage.setItem('user', JSON.stringify(res.data.data));
            setTimeout(() => {
              navigate('/');
            }, 3000);
          } else
            setSuccess(true);
        }
      })
      .catch(err => {
        console.log(err.response?.data);
        if (err.response?.data?.message?.email && err.response.data.message.email[0] === 'The email has already been taken.' && socialLogin) {
          // If the account already exists, try logging in
          let loginPayload = {
            email: payload.email,
            login_plateform: payload.login_plateform,
            login_plateform_id: payload.login_plateform_id,
            password: payload.password,
          };
          callLoginAPI(loginPayload);
        } else {
          setError("An error occurred while signing you up.");
          setErrorObj(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false))
  }

  const callLoginAPI = (payload) => {
    setError("");
    setLoading(true);
    console.log('Payload', payload);

    axios
      .post(`${baseURL}/login`, payload)
      .then(res => {
        console.log('Login Response', res.data);
        if (res.data.status === 200) {
          setSuccess("autoLogin");
          localStorage.setItem('token', res.data.access_token);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setTimeout(() => {
            navigate('/');
          }, 3000);
          
        }
      })
      .catch(err => {
        console.log(err.response?.data);
        if (err.response?.data?.message === "Invalid Password Or Email Address")
          setError("Invalid email address or password");
        else
          setError("An error occurred while logging in.");

      })
      .finally(() => setLoading(false))
  }

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log('Token Response', tokenResponse);
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`
            }
          })
        .then(res => {
          console.log('Google API res', res.data);
          let payload = {
            name: res.data.name,
            email: res.data.email,
            login_plateform: 'Gmail',
            login_plateform_id: res.data.sub,
            password: res.data.sub,
          }
          callSignUpAPI(payload, "Gmail");
        })
        .catch(err => {
          console.log(err);
        })
    },
    onError: errorResponse => {
      console.log('Google Login Error', errorResponse);
    },
  });

  useEffect(() => {
    if (localStorage.getItem('token'))
      navigate('/profile');
  }, [navigate]);

  return (
    <>
      <div className="signup-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              {success === "autoLogin" ?
                <div className="form-cont py-5">
                  <h3 className="mt-2">{t("Success")}</h3>
                  <p className="my-4">You are now being logged in. Please wait...</p>
                </div>
                :
                success ?
                  <div className="form-cont">
                    <h3 className="mt-2">{t("Success")}</h3>
                    <p className="my-5">Your account has been created successfully! You can proceed to the login page to login with your credentials.</p>
                    <Link to="/login">
                      <Button className="login-btn">
                        Login
                      </Button>
                    </Link>
                  </div>
                  :

                  <div className="form-cont">
                    <div className="login-header">
                      <Link to="/#">
                        <img src={Arrow} alt="arrow" />
                      </Link>
                      <p>
                        {t("Already a member?")} <Link to="/login">{t("Sign In")}</Link>
                      </p>
                    </div>
                    <h3>{t("Sign Up")}</h3>
                    <p>{t("Secure Your Communications with Easymail")}</p>
                    <form>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{t("Full Name")}*</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder={t("Full Name")}
                          value={userName}
                          onChange={e => setUserName(e.target.value)}
                          autoComplete="none"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{t("Email Address")}*</label>
                        <input
                          type="email"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder={t("Email Address")}
                          value={userID}
                          onChange={e => {
                            setUserID(e.target.value)
                            setErrorObj({ ...errorObj, email: "" })
                          }}
                          autoComplete="none"
                        />
                        <span className="error form-field-error">{errorObj?.email && errorObj.email[0]}</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Password")}*</label>
                        <div className="password-input">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="**********"
                            value={password}
                            onChange={updatePasswordAndValidate}
                            autoComplete="none"
                          />
                          <button type="button" onClick={togglePassword}>
                            {passwordShown ? (
                              <img src={Hide} alt="hide" />
                            ) : (
                              <img src={Show} alt="show" />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Confirm Password")}*</label>
                        <div className="password-input">
                          <input
                            type={confirmpasswordShown ? "text" : "password"}
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="************"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            autoComplete="none"
                          />
                          <button type="button" onClick={toggleConfirmPassword}>
                            {confirmpasswordShown ? (
                              <img src={Hide} alt="hide" />
                            ) : (
                              <img src={Show} alt="show" />
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                    <ul className="pass-list list-unstyled">
                      <li>
                        <img src={passwordValidation[0] ? Okay : Icon} alt="list-icon" />
                        {t("At least 8 characters")}
                      </li>
                      <li>
                        <img src={passwordValidation[1] ? Okay : Icon} alt="list-icon" />
                        {t("At least one number (0-9) or a symbol")}
                      </li>
                      <li>
                        <img src={passwordValidation[2] ? Okay : Icon} alt="list-icon" />
                        {t("Lowercase (a-z) and uppercase (A-Z)")}
                      </li>
                    </ul>
                    <p className="error mb-4">{error}</p>
                    <div className="login-btns">
                      <Button
                        className="login-btn"
                        disabled={loading || !userName || !userID || !password || !confirmPassword || passwordValidation.includes(false)}
                        onClick={signUp}
                      >
                        {loading ?
                          <div className="spinner-border" />
                          :
                          <>
                            {t("Sign Up")} <img src={BtnArrow} alt="arrow" />
                          </>
                        }
                      </Button>
                      <h4>{t("OR")}</h4>
                      <FacebookLogin
                        appId="2363255820519921"
                        fields="name,email,picture"
                        scope="public_profile,email"
                        onSuccess={(response) => {
                          console.log('Login Success!', response);
                        }}
                        onFail={(error) => {
                          console.log('Login Failed!', error);
                        }}
                        onProfileSuccess={(res) => {
                          console.log('Get Profile Success!', res);
                          let payload = {
                            name: res.name,
                            email: res.email,
                            login_plateform: 'Facebook',
                            login_plateform_id: res.id,
                            password: res.id,
                          }
                          callSignUpAPI(payload, "Facebook");
                        }}
                        render={({ onClick }) => (
                          <div
                            className="social-login"
                            onClick={onClick}
                          >
                            <img src={Facebook} alt="facebook" />
                          </div>
                        )}
                      />
                      {/* <div
                        className="social-login"
                        onClick={() => {
                          googleLogin();
                        }}
                      >
                        <img src={Facebook} alt="facebook" />
                      </div> */}
                      <div
                        className="social-login"
                        onClick={() => {
                          googleLogin();
                        }}
                      >
                        <img src={Google} alt="google" />
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className="col-md-6">
              {" "}
              <img
                className="img-fluid ml-auto d-block"
                src={Banner}
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
