import React, { useEffect } from "react";
import "../../assets/scss/_footer.scss";
import i18n from "i18next";
import * as _ from "../Lang/Lang";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Select } from "antd";
import Instagram from "../../assets/images/instagram.png";
import Twitter from "../../assets/images/twitter.png";
import Github from "../../assets/images/github.png";
import Cat from "../../assets/images/cat.png";

const Footer = (props) => {
  const { Option } = Select;
  const { setLanguage } = props;
  const { t } = useTranslation();
  const langChange = (value) => {
    console.log(`selected ${value}`);
    setLanguage(value);
    localStorage.setItem("lang", value);
    const lang = localStorage.getItem("lang");
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    setLanguage(
      localStorage.getItem("lang") ? localStorage.getItem("lang") : "gm"
    );
  });

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-box">
                <ul className="list-unstyled footer-nav-list">
                  <li>
                    <Link>{t("How to Buy")}</Link>
                  </li>
                  <li>
                    <Link to="/faqs">{t("FAQs")}</Link>
                  </li>
                  <li>
                    <Link to="/privacy">{t("Privacy Policy")}</Link>
                  </li>
                  <li>
                    <Link to="/terms">{t("Terms and Conditions")}</Link>
                  </li>
                </ul>
                <div>
                  <p>&copy; 2023 PAYBIUS SWAP</p>
                  <Select
                    defaultValue={
                      localStorage.getItem("lang")
                        ? localStorage.getItem("lang")
                        : "De"
                    }
                    style={{ width: 70 }}
                    onChange={langChange}
                  // open="true"
                  >
                    <Option value="en">EN</Option>
                    <Option value="gm">DE</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRight">
          <ul className="social-list list-unstyled">
            <li>
              <Link>
                <img src={Instagram} alt="Instagram" />
              </Link>
            </li>
            <li>
              <Link>
                <img src={Twitter} alt="twitter" />
              </Link>
            </li>
            <li>
              <Link>
                <img src={Github} alt="github" />
              </Link>
            </li>
            <li>
              <Link>
                <img src={Cat} alt="Cat" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
