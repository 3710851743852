import React, { useEffect, useState, useRef } from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Setting from "../../assets/images/setting.png";
import Warning from "../../assets/images/warning.png";
import True from "../../assets/images/ok1.png";
import I from "../../assets/images/ig.png";
import axios from "axios";
import { baseURL } from "../../constants";

import { ReactSVG } from "react-svg";
import Delete from "../../assets/images/delete.svg";
import Eye from "../../assets/images/eye.svg";
import Wallet from "../../assets/images/wallet.svg";
import Search from "../../assets/images/search.png";
import "../../assets/scss/_transactions.scss";
import "../../assets/scss/_withdraw.scss";

const Withdraw = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dataFetched = useRef(false);
  const {t} = useTranslation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setError(false);
      setLoading(true);
      axios
        .get(`${baseURL}/transactions`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          console.log('Transactions', res.data);
          setTransactionData(res.data?.data);
        })
        .catch(err => {
          setError(true);
          console.log(err.response?.data);
        })
        .finally(() => setLoading(false));
    } else {
      setError("no_token");
    }
  }, [dataFetched]);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="transactions">
      <div className="transactions-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in to Search"
                />
                <img className="search-icon" src={Search} alt="search" />
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={Setting} alt="setting" />
                    Setting
                  </button>
                  <div className="dropdown-menu">
                    <h6>Types</h6>
                    <Checkbox onChange={onChange}>Any Types</Checkbox>
                    <Checkbox onChange={onChange}>Purchase</Checkbox>
                    <h6>Status</h6>
                    <Checkbox onChange={onChange}>Show All</Checkbox>
                    <Checkbox onChange={onChange}>Approved</Checkbox>
                    <Checkbox onChange={onChange}>Pending</Checkbox>
                    <Checkbox onChange={onChange}>Canceled</Checkbox>
                    {/* <Link className="dropdown-item" href="#">
                  Action
                </Link>
                <Link className="dropdown-item" href="#">
                  Another action
                </Link>
                <Link className="dropdown-item" href="#">
                  Something else here
                </Link> */}
                  </div>
                </div>
                {/* <Button>
              <img src={Setting} alt="setting" />
              Setting
            </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transactions-data">
        <div className="container">
          {loading ?
            <div className="text-center py-5 my-5">
              <div className="spinner-border" />
              <p className="mt-2">Loading data...</p>
            </div>
            :
            error ?
              <div className="text-center py-5 my-5">
                <h4>{error === "no_token" ? t("You must login to view transactions.") : t("Unable to fetch data!")}</h4>
              </div>
              :
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Transaction No</th>
                      <th>Token</th>
                      <th>Amount</th>
                      <th>EUR Amount</th>
                      <th>To</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData?.map((item, index) => (

                      <tr key={index}>
                        <td>
                          <img src={True} alt="check" />
                          {item.transaction_no}
                          <span>2018-08-24 10:45PM</span>
                        </td>
                        <td>
                          {item.token}
                          <span>PBC</span>
                        </td>
                        <td>
                          {item.amount}
                          <span>
                            ETH <img src={I} alt="hint" />
                          </span>
                        </td>
                        <td>
                          {item.eur_amount}
                          <span>
                            EUR <img src={I} alt="hint" />
                          </span>
                        </td>
                        <td>
                          {item.to}
                          <span>{new Date(item.created_at).toLocaleString()}</span>
                        </td>
                        <td>
                          <Tooltip placement="top" title="Pay Now">
                            <Button className="wallet-btn">
                              <ReactSVG src={Wallet} alt="wallet" />
                            </Button>
                          </Tooltip>
                          <Tooltip placement="top" title="View">
                            <Button
                              className="transcation-btn eye-btn"
                              data-toggle="modal"
                              data-target="#transaction"
                            >
                              <ReactSVG src={Eye} alt="eye" />
                            </Button>
                          </Tooltip>
                          <Tooltip placement="top" title="Delete">
                            <Button className="delete-btn">
                              <ReactSVG src={Delete} alt="delete" />
                            </Button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
          }
          {!loading && transactionData.length === 0 &&
            <div className="warning-box">
              <img src={Warning} alt="warning" />
              <h4>No Data Found</h4>
            </div>
          }

          {/* Transaction Modal */}
          <div
            className="modal fade"
            id="transaction"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div className="modal-body">
                  <div className="payment-modal-cont">
                    <h4>Transaction Details</h4>
                    <div className="transaction-header">
                      <div>
                        <h5>Tranx Date</h5>
                        <p>24 Jul, 2018 10:11PM</p>
                      </div>
                      <div>
                        <h5>Tranx Status</h5>
                        <p>Tranx Status</p>
                      </div>
                      <div>
                        <h5>Tranx Approved Note</h5>
                        <p>By Admin at 24 Jul, 2018 10:12PM</p>
                      </div>
                    </div>
                    <div className="transaction-info">
                      <h4>TRANSACTION INFO</h4>
                      <div>
                        <h5>Transaction Type</h5>
                        <p>Purchase</p>
                      </div>
                      <div>
                        <h5>Payment Getway</h5>
                        <p>Ethereum - Offline Payment</p>
                      </div>
                      <div>
                        <h5>Deposit From</h5>
                        <p>0xa87d264f935920005810653734156d3342d5c385</p>
                      </div>
                      <div>
                        <h5>Tx Hash</h5>
                        <p>
                          Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93
                        </p>
                      </div>
                      <div>
                        <h5>Deposit To</h5>
                        <p>0xa058106537340385156d3342d5c87d264f935920</p>
                      </div>
                      <div>
                        <h5>Details</h5>
                        <p>Tokens Purchase</p>
                      </div>
                    </div>
                    <div className="token-details">
                      <h4>TOKEN DETAILS</h4>
                      <div>
                        <div>
                          <h5>Stage Name</h5>
                          <p>ICO Phase 3</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Contribution</h5>
                          <p>
                            1.000 ETH <img src={I} alt="hint" />
                          </p>
                        </div>
                        <div>
                          <p>
                            <img src={I} alt="hint" />
                            $2540.65
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Tokens Added To</h5>
                          <p>
                            UD1020001 <small>- infoicox@gmail..com</small>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Token (T)</h5>
                          <p>4,780.00</p>
                        </div>
                        <div>
                          <p>(4780 * 1)</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Bonus Tokens (B)</h5>
                          <p>956.00</p>
                        </div>
                        <div>
                          <p>(956 * 1)</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h5>Total Tokens</h5>
                          <p>5,736.00</p>
                        </div>
                        <div>
                          <p>(T+B)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Transaction Modal */}
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
